<template>

    <div style="display: flex; flex-direction: column;" class="p-field">
        <label class="mt-4 mb-4 p-card-subtitle">Where do you want to create the new Document or Page</label>
        <InputGroup>
            <InputGroupAddon>
                <i class="pi pi-folder"></i>
            </InputGroupAddon>
            <AutoComplete v-model="locationValue" dropdown :suggestions="items" @complete="search" />
        </InputGroup>

    </div>
    <span class="w-full mt-4 mb-4 p-card-subtitle">Your Prompt</span>
    <div style="display: flex; position: relative;">
        <Textarea placeholder="Your document prompt, for example 'A new Overview Page on our python sdk', 'a blog post and telemetry', and 'etc'" class="w-full mt-4 mb-4" autoResize v-model="value" variant="filled" autofocus fluid rows="2" />
        <Button @click="generateTask" class="mt-6 mb-4 mr-4" icon="pi pi-send" rounded aria-label="Filter"
            style="position: absolute; top: 20; right: 0;" />
    </div>

    <Skeleton v-if="loading" width="100%" height="10rem">

    </Skeleton>

    <div v-if="response">
        <a :href="response.pullUrl" target="_blank" class="p-card-subtitle">View your new Document or Page</a>
        <ScrollPanel style="width: 100%; height: 200px">
            <pre>
                <code>
                    {{ response.content }}
                </code>
            </pre>
        </ScrollPanel>
    </div>

</template>


<script>
import ApiKey from '@/views/ApiKey.vue';



export default {
    props: {
        repo: String,
    },
    data() {
        return {
            value: '',
            locationValue: 'docs',
            items: ["docs", "blog"],
            loading: false,
            response: null
        }
    },
    methods: {
        search(event) {
            this.items = ["docs", "blog"]
        },
        async generateTask() {
            this.loading = true
            const myHeaders = new Headers();
            let page = false
            let sub_folder;
            if(this.locationValue.startsWith("docs/")) {
                sub_folder = this.locationValue.split("docs/")[1] || "docs"
            } else {
                page = true
                sub_folder = this.locationValue
            }
            let task = this.value
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                let githubRepository = this.repo || `team-dev-docs/${org}-dev-docs`
                const raw = JSON.stringify({ task, githubRepository, sub_folder, page, apiKey: "dsfds" });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo= await fetch(`${url}/task`, requestOptions);
                this.loading = false
                this.value = ''
                const result = await saveResponseTwo.json();

                this.response = {content: result.content, branch: result.branch, pullUrl: `https://github.com/${githubRepository}/pulls`}
            } catch (e) {

                return {}
            }
        }
    }
}

</script>