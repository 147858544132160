<template>
    <editor />
</template>
<script>
import { defaultMarkdownParser, ParseSpec, schema, MarkdownParser, MarkdownSerializer } from '@tiptap/pm/markdown'

const markdown = defaultMarkdownParser.parse(`## yo i am cool`)


import Editor from "@/components/ExternalEditor.vue";
export default {
    components: {
        Editor
    }
}
</script>