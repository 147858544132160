import { lo, dump } from 'js-yaml';

class SnippetsInstance {
  githubActionCode(org, endpoint, name, options = {}) {
    let text = `
name: ${name}
on:
  push:
    branches:
      - main
    paths:
      - '**/*.js'

jobs:
  dev-docs-app:
    runs-on: ubuntu-latest

    steps:
      - name: Checkout Repository
        uses: actions/checkout@v2

      - name: Send Update to DevDocs
        run: |
          TIMESTAMP=$(date -u +"%Y-%m-%dT%H:%M:%SZ")
          API_KEY=\${{ secrets.API_KEY }}
          REPO_GH_PAT=\${{secrets.REPO_GH_PAT}}
          GITHUB_REPOSITORY="\${{ github.repository }}"
          GITHUB_ACTOR="\${{ github.actor }}"
          curl -X POST -H "Content-Type: application/json" \\
               -H "X-API-KEY: $API_KEY" \
               -d '{"apiKey": "'$API_KEY'", "repo_gh_pat": "'$REPO_GH_PAT'", "timestamp": "'$TIMESTAMP'", "githubRepository": "'$GITHUB_REPOSITORY'", "githubActor": "'$GITHUB_ACTOR'"}' \\
               "\${{ secrets.GENERATE_ENDPOINT_URL }}"
        env:
          API_KEY: https://api.devdocsapp.com/company/${org}/${endpoint}`
    return text
  }
}

export default SnippetsInstance;