<template>
    <NodeViewWrapper>

        <details style="
          background: #110c0c;
          margin: 1em 0;
          border: 1px solid #ddd;
          border-radius: 5px;
        ">
            <summary style="
            padding: 1em;
            background: #f2f2f2;
            cursor: pointer;
            border-bottom: 1px solid #ddd;
            font-weight: bold;
            text-align: left;
            outline: none;
            text-align: left;
          ">
                <i :class="node?.attrs?.icon || 'fa-solid fa-square-check'"></i>
                {{ node?.attrs?.title }}
            </summary>
            <NodeViewContent />
        </details>
    </NodeViewWrapper>
</template>

<script>

import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import chroma from "chroma-js"

export default {
    components: {
        NodeViewWrapper,
        NodeViewContent,
    }

}

</script>