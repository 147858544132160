<template>
    <Dialog id="setup_action" header="Finish Github App Setup" v-model:visible="openSetupModal">
        <div class="p-card-subtitle">Associate your Org in Dev-Docs to your repo</div>
        <div class="mt-8">
            <InputText class="w-full" v-model="username" placeholder="Username" disabled />
            <div class="mt-4" style="display: flex; flex-direction: row;">
                <Select v-model="org" :options="orgs" placeholder="Select a City" class="w-full md:w-56" /> 
                <Button icon="pi pi-arrow-right" text rounded aria-label="Filter" />
                <Select v-model="org" :options="orgs" placeholder="Select a City" class="w-full md:w-56" />
            </div>


        </div>
        <div class="mt-4" style="display: flex; flex-direction: row;">
            <Button>Complete Github App Setup</Button>
        </div>
       
    </Dialog>

</template>

<script>

export default {
    data: () => ({
        openSetupModal: false,
        username: '',
        org: null,
        orgs: ['org1', 'org2'],
        userInfo: null,
        items: [
            {
                label: 'Refresh',
                icon: 'pi pi-refresh'
            },
            {
                label: 'Export',
                icon: 'pi pi-upload'
            }
        ]
    }),
    methods: {
        async close() {
            this.$authInstance.logout()
            // this.openSetupModal = false;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        setOrg(options) {
            console.log(options.org);
            this.org = options.org
        }
    },
    async mounted() {
        this.openSetupModal = true;
        let userInfo = await this.$authInstance.userinfo();
        console.log(userInfo);
        this.username = userInfo.displayName || userInfo.name
        this.orgs = await this.$authInstance.getOrgs()
        this.org = await this.$authInstance.getOrg()
    }
}

</script>