<template>
    <node-view-wrapper :style="createStyle" class="draggable-item">
        <div class="inner-card">
            <i :class="classString"></i>
            <node-view-content style="margin-left: 1em;" />
        </div>
    </node-view-wrapper>
</template>
  
<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import chroma from "chroma-js"

export default {
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: nodeViewProps,
    data: () => ({
        styleString: null,
        classString: null,
        styles: {
            warning:
            {
                color: "#FFCC19",
                class: "fa-solid fa-triangle-exclamation"
            },
            note:
            {
                color: "#14A38B",
                class: "fa-solid fa-circle-exclamation"
            },
            info: 
            {
                color: "#232429",
                class: "fa-solid fa-circle-info"
            },
            check:
            {
                color: "#14A38B",
                class: "fa-solid fa-square-check"
            }
        }
    }),
    mounted() {
        const type = this.node?.attrs?.type
        const color = this.styles[type].color
        let darkerColor = chroma(color).darken().hex();
        this.styleString = `border: 1px solid ${darkerColor}; background: linear-gradient(30.17deg, rgba(255, 204, 25, 0) -4.93%, ${color} -4.91%, rgba(255, 204, 25, 0) 105.13%); padding: 0em;`
        this.classString = this.styles[type].class
    },
    computed: {
        createStyle() {
            return this.styleString
        },
        createClass() {
            return this.classString
        }
    }
}
</script>

<style>
.inner-card {
    background: radial-gradient(100% 359.18% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    padding: 1em;
    backdrop-filter: blur(15px);
    width: 100%;
    border-radius: 0.5rem;
    /* Note: backdrop-filter has minimal browser support */
}
</style>