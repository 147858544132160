<template>
    <figure class="px-10 pt-10 pb-10">
      <h1 class="text-left">Misc Docs</h1>
    </figure>

  
  
    <div class="docs-holder">
  
    <div v-if="codebases && checkedSync">
      <el-row v-for="(array, index) of codebases" :gutter="20" v-bind:key="index">
        <el-col style="margin-top: 2em;" v-for="(codebase, index) of array" :span="24" v-bind:key="index">
            <el-card class="box-card">
                    <figure class="text-right pt-2">
                        <el-check-tag :checked="codebase.checked" @change="syncCodebase(codebase)">Sync</el-check-tag>
                    </figure>
                    <figure class="pt-2">
                        <h2 class="text-left">{{codebase.friendly_name}}</h2>
                    </figure>
                    <figure>
                        <h3 class="text-left">Language: {{codebase.language}}</h3>
                    </figure>
                    <div class="pt-10">
                        <figure>
                            <p class="text-left">Description: Blah blah</p>
                        </figure>
                        <figure>
                            <p class="text-left">Owner: team X</p>
                        </figure>
                    </div>
                </el-card>
        </el-col>
      </el-row>
    </div>
    </div>
  </template>
  
  <script>
  
  // for every foruth element



   
  var cutItemsBy4 = function (bigarray) {
    var size = 1; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
      arrayOfArrays.push(bigarray.slice(i, i + size));
    }
    
    return arrayOfArrays
  }

  var getKeySpace = function(repo) {
    return repo.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  }
  
  
  export default {
    props: {
        integration: String
    },
    data: () => ({
      codebases: null,
      friendlyNames: null,
      showWelcome: false,
      org: null,
      url: null,
      checkedSync: false
    }),
    async mounted() {
      //var authStatus = await this.$authInstance.authnActionIfNeeded()
      var idtoken = await this.$authInstance.getToken()
      this.url = await this.$authInstance.getBaseUrl()
      this.org = await this.$authInstance.getOrg()
      
      await this.getDocs()
      //this.showWelcome = this.codebases == null
      await this.getCodebasesFromIntegrations()
    },
    methods: {
      getFileName(file) {
        var fileFragments = file.split("/")
        return fileFragments[fileFragments.length - 1]
      },
      async syncCodebase(cb) {
        var myHeaders = new Headers();
          var idtoken = await this.$authInstance.getToken()
          myHeaders.append("Authorization", `Bearer ${idtoken}`);
            var raw = JSON.stringify({...cb, integration: this.integration})
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw,
            redirect: 'follow'
          };
  
          var codebaseRes = await fetch(`${this.url}/integrated_codebases`, requestOptions)
          var codebasesJson = await codebaseRes.json()
          
        cb.checked = true
      },
      getcodebase(codebase) {
        
        //this.$emit("set", codebase.content)
      },
      getRepoName(repo) {
        var repoFragments = repo.split("/")
        return repoFragments[repoFragments.length - 1].split(".git")[0]
      },
      async getCodebasesFromIntegrations() {
        const component = this
        var myHeaders = new Headers();
          var idtoken = await this.$authInstance.getToken()
          myHeaders.append("Authorization", `Bearer ${idtoken}`);
  
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
  
          var codebaseRes = await fetch(`${this.url}/integrated_codebases?searchKey=name&searchValue=${this.integration}`, requestOptions)
          var codebasesJson = await codebaseRes.json()
          
      
         
          var newCodebases = this.codebases.map(function(item) {
            var codebaseExists;
            if(component.integration == "confluence") {
                
                item["checked"] = component.checkSyncForConfluence({codebaseData: codebasesJson, codebase: item})
            }
            return item
         })
         this.codebases = cutItemsBy4(newCodebases)
         
         this.checkedSync = true
//lol
      },
      checkSyncForConfluence(cbData) {
        var confResults = cbData?.codebaseData?.results
        
        var existingItem = getKeySpace(cbData?.codebase?.repo)
        var foundItem = confResults.find(function(item) {
            
            
            return item.key == existingItem
        })
        return foundItem != undefined
      },
      generateIdLink(repo) {
        var encodedStringBtoA = btoa(repo);
        return `/docs/${encodedStringBtoA}`
      },
      async getDocs() {
        try {
          var myHeaders = new Headers();
          var idtoken = await this.$authInstance.getToken()
          myHeaders.append("Authorization", `Bearer ${idtoken}`);
  
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
  
          var codebaseRes = await fetch(`${this.url}/codebases`, requestOptions)
          var codebasesJson = await codebaseRes.json()
          
          this.codebases = codebasesJson.data
  
        } catch (e) {
          
        }
      }
    }
  }
  </script>
  
  <style>
  /* .docs-holder {
    padding-left: 1em;
  } */
  </style>