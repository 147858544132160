<template>
    <el-popover placement="top" :width="400" :visible="showPopup">
        <template #reference>
            <el-button @click="openPopup()">Ai Summarizer</el-button>
        </template>
        <div v-if="showPopup">
            <div class="card-actions justify-end">
                <el-button @click="showPopup = false">
                    <el-icon>
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                            <path fill="currentColor"
                                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                            </path>
                        </svg>
                    </el-icon>
                </el-button>
            </div>
            <h2 class="card-title">Enhance Selection with AI</h2>
            <div style="word-break: break-all; overflow: scroll; margin-top: 2em;">
                <h3>Selected Text</h3>
                <code>{{ text }}</code>
            </div>
            <div style="margin-top: 2em;" class="card-actions">
                <el-button size="small" @click="command = 'Summarize this text'">Summarize this text</el-button>
                <el-button size="small" @click="command = 'Explain this text'">
                    Explain this text
                </el-button>
                <el-button @click="command = 'Explain this code'" size="small">
                    Explain this code
                </el-button>
                <el-button @click="command = 'Use this text to create a article'" size="small">
                    Use this text to create a article
                </el-button>
            </div>

            <el-input v-model="command" class="w-50 m-2" placeholder="Ask the AI to generate" @keyup.enter="updateBlock()">
                <template #prefix>
                    <span>🦾🤖</span>
                </template>
            </el-input>
        </div>
    </el-popover>
</template>
  
<script>


//use like so const updatedCSSString = replaceCSSProperty(cssString, { property: 'height', value: '150px' });

export default {
    props: {
        editor: Object
    },
    data: () => ({
        error: null,
        loading: null,
        command: null,
        showPopup: false,
        text: null,
        from: null,
        to: null
    }),
    watch: {
        showPopup(val) {
            if (!val) this.$emit("close")
        }
    },
    methods: {
        isSelection() {
            const { state } = this.editor
            const { selection } = state
            
            if (selection) return
            else this.showPopup = false
            return
        },
        openPopup() {
            const { state } = this.editor
            const { selection } = state
            const { from, to } = selection
            this.text = state.doc.textBetween(from, to, ' ')
            this.showPopup = true

        },
        async updateBlock(options = {}) {
            const { state } = this.editor
            const { selection } = state
            const { from, to } = selection
            const text = state.doc.textBetween(from, to, ' ')
            this.error = false
            this.loading = true
            
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var raw = JSON.stringify({ message: `${this.command} : ${this.text}` })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()
                
                this.command = ""
                this.loading = false

                // this.editor.commands.insertContent(result?.content)
                this.editor.chain().insertContentAt(to, `<p></p><p></p>`).insertContent(result?.content).run()
                this.showPopup = false
            } catch (e) {
                this.error = true
                
            }
        }

    }
};
</script>
  

