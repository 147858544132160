export var getCssUpdateData = function(inputCss) {
    const attributeData = inputCss
      .split(';')
      .map((rule) => rule.trim())
      .filter((rule) => rule)
      .map((rule) => {
        const [attribute, ...valueParts] = rule.split(':');
        const newValue = valueParts.join(':').trim();
        return { attribute: attribute.trim(), newValue };
      });
    return attributeData
}

export var updateCssAttributes = function(updateData, targetCss) {
    const attributesToSkip = [];

    // Function to create a RegExp to match the attribute and its value
    const createRegex = (attribute) => new RegExp(`(${attribute}\\s*:\\s*)[^;]+(;|$)`, 'gi');

    // Iterate through the attributes and update the target CSS string
    updateData.forEach(({ attribute, newValue }) => {
      if (!attributesToSkip.includes(attribute)) {
        const regex = createRegex(attribute);
        const found = targetCss.match(regex);

        if (found) {
          // Update the existing attribute
          targetCss = targetCss.replace(regex, `$1${newValue}$2`);
        } else {
          // Add the new attribute to the target CSS string
          targetCss += ` ${attribute}: ${newValue};`;
        }
      }
    });

    return targetCss;
  }