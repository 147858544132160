<template>
    <node-view-wrapper style="max-width: 50%">
        <div style="max-height: 50%; max-width: 50%; overflow: scroll">
            <h1>hey</h1>
        </div>
    </node-view-wrapper>
</template>

<script>

import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import katex from 'katex';
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Editor, EditorContent } from '@tiptap/vue-3'
import ExternalEditorVue from '../ExternalEditor.vue';
import DraggableItem from './DraggableItem';


export default {
    components: {
        NodeViewWrapper,
        EditorContent
    },
    props: nodeViewProps,
    data() {
        return {
            editorOne: null,
            editorTwo: null,
            editorThree: null
        }
    },
    mounted() {
        // 
        // this.editor.commands.blur()
        this.editorOne = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                DraggableItem
            ],
            content: `
      <p>This is a boring paragraph.</p>
        <div data-type="draggable-item">
          <p>Followed by a fancy draggable item.</p>
        </div>
        <div data-type="draggable-item">
          <p>And another draggable item.</p>
          <div data-type="draggable-item">
            <p>And a nested one.</p>
            <div data-type="draggable-item">
              <p>But can we go deeper?</p>
            </div>
          </div>
        </div>
        <p>Let’s finish with a boring paragraph.</p>
      `,
        })
        //this.editorOne.commands.focus()
        this.editorTwo = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text
            ],
            content: `
 
      `,
        })
        this.editorThree = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text
            ],
            content: `
   
      `,
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}


</script>

<style lang="css">
.columns-component {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 5px 0;
    background: #736BF5;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
}

.columns-component.is-selected {
    border-color: rgb(243, 245, 247);
}

.columns-component h3 {
    margin: 0;
}

.columns-component__title {
    display: flex;
    margin-bottom: 20px;
}

.columns-component__title a {
    margin-left: auto;
}

.columns-component__formula {
    margin-top: 10px;
    padding: 10px;
    margin-top: 10px;
    background: rgb(238 230 230) !important;
    padding: 10px;
    border-radius: 1em;

}

.columns-component textarea {
    padding: 10px;
    font-size: 1.2rem;
}
</style>
  
    