<template>
    <div class="create-team-form flex-shrink-0 w-full max-w-sm">
        <figure class="px-10 pt-10">
            <h1>Let’s create your first team</h1>
        </figure>

        <div class="card-body">
            <div class="form-control">
                <label>
                    <span class="label-text">Name of your org/company</span>
                </label>
                <input type="text" v-model="org_name" placeholder="org name" class="input input-bordered mt-4" />
            </div>
            <div class="form-control mt-6">
                <button @click="createOrg()" class="btn btn-primary">Finish set up</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        org_name: '',
        team_name: ''
    }),
    methods: {
        async createOrg() {
            // Validation check
            let teamName = this.org_name.toLowerCase().trimEnd().trimStart()
            let orgName = this.org_name.toLowerCase().trimEnd().trimStart()
            const regex = /[^a-z0-9-]+/;
            if (regex.test(orgName) || regex.test(teamName)) {
                alert('Invalid input. Please use only alphanumeric lowercase characters and hyphens.');
                return;
            }
            if(orgName == '') {
                alert('Org Name is Mandatory');
                return;
            }

            // Rest of your code
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken();
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                org_name: orgName,
                team_name: teamName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getUrl();
            var response = await fetch(`${url}/org`, requestOptions);
            var responseJson = await response.json();
            console.log("what is the response json", responseJson)

            var idtoken = await this.$authInstance.renew();
            if(responseJson.message == "ALREADY_EXISTS") {
                window.alert("Current Org Name Already Exists, Try Different Team")
            } else {
                await window.localStorage.setItem('org', this.org_name);
                window.location.href = `${window.location.origin}/dash`;
            }
         
            //return responseJson
        }
    }
}
</script>

<style>
.create-team-form {
    input {
        border-radius: 4px;
        border: 0.5px solid #736AFD;
        background: rgba(115, 106, 253, 0.10);

    }

    button {
        border-radius: 6px;
        background: #736AFD;
    }

}

</style>