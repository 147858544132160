<template>
        <div class="content">
            <div class="card shadow-xl">
                <div class="card-body">
                    <textarea v-model="command" class="textarea textarea-primary" placeholder="ask your question"></textarea>
                    <h3 v-if="loading">Loading 🤖....</h3>
                    <h3 v-if="error" style="color: red;">This was an error</h3>
                    <pre @input="handleInput" ref="codeBlock" contenteditable="true"><code></code></pre>
                    <div v-if="lang != 'css'" @change="testMethod" v-html="output"></div>
                    <div class="card-actions justify-end">
                        <el-button v-if="output" @click="reRender()">Re Render</el-button>
                        <el-button v-if="output" @click="enhance()">Enhance generated Div</el-button>
                        <el-button v-if="output" @click="reset()">reset</el-button>
                        <el-button v-if="output" @click="insertContent()">insert</el-button>
                        <el-button @click="updateBlock()">Run Command</el-button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { CodeBlock } from '@tiptap/extension-code-block'
import StarterKit from '@tiptap/starter-kit'


import { Codemirror } from 'vue-codemirror'
import { html } from '@codemirror/lang-html'


var toParseAble = function (htmlString) {

    var html = htmlString
    const regex = /style\s*=\s*(['"])(.*?)\1/g;
    html = html.replace(regex, "customStyleString=\"$2\"");

    
    return html
}

export default {
    components: {
        Codemirror, EditorContent
    },
    props: {
        lang: String, currentCode: String 
    },
    data: () => ({
        command: null,
        saved: null,
        output: null,
        htmlEditor: null,
        initialized: false,
        loading: false,
        error: false,
        code: ''
    }),
    watch: {
        output(val) {
            
            const codeBlock = this.$refs.codeBlock;
            codeBlock.textContent = val
        },
        initialized(val) {
            
        },
        currentCode(val) {
            this.output = val
        }
    },
    async mounted() {
        var component = this
        this.output = this.currentCode
        this.command = this?.node?.attrs?.command
        this.htmlEditor = new Editor({
            async onUpdate({ editor }) {
                const text = await editor.getText()
                
                component.$emit("style", text)
            },
            editorProps: {
                handleClickOn({ view }) {
                    
                    
                }
            },
            spellcheck: false,
            extensions: [
                Document,
                Text,
                StarterKit,
                HardBreak.extend({
                    addKeyboardShortcuts() {
                        return {
                            Enter: () => this.editor.commands.newlineInCode()
                        }
                    }
                }),
                Dropcursor,
                CustomListItem,
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlock
                    .extend({
                        addKeyboardShortcuts() {
                            return {
                                // ↓ your new keyboard shortcut
                                'Enter': (editorObject) => {
                                    
                                    const { editor } = editorObject
                                    return false
                                    // editor.commands.chain().focus().setHardBreak().run()
                                },
                            }
                        }
                    })
                    .configure({
                        exitOnArrowDown: false, exitOnTripleEnter: false, HTMLAttributes: {
                            class: 'language-html',
                        }
                    })
            ],
            content: ''
        })
        this.htmlEditor.commands.setCodeBlock()
        
        this.initialized = true
    },
    methods: {
        testMethod() {
            
        },
        toFullScreen() {
            const codeBlock = this.$refs.codeBlock;
            codeBlock.documentElement.requestFullscreen();
        },
        handleInput(e){
            
            const codeBlock = this.$refs.codeBlock;
            
            
            this.output = codeBlock.textContent
                    //this.content = e.target.innerHTML
        },
        async reRender() {
            const codeBlock = this.$refs.codeBlock;
            this.output = codeBlock.textContent
        },
        async reset() {
            this.output = null
        },
        async enhance() {
            const codeBlock = this.$refs.codeBlock;
            this.output = codeBlock.textContent
            var historicalMessage = `Here is the existing the code ${this.output}. Can you Modify or add the existing code with these instructions: ${this.command}`
            await this.updateBlock({ historicalMessage })
        },
        async insertContent() {
            var parseAble = toParseAble(this.output)
            
            this.editor.commands.insertContent(`${parseAble}`)
        },
        async captureGeneration(val) {
            
        },
        async autoEmit() {
            if(this.lang == "css") this.$emit("airesult", this.output)
        },
        async updateBlock(options = {}) {
            
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ message: options.historicalMessage || this.command, css: true })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()
                

                this.output = result?.content
                this.command = ""
                this.autoEmit()


            } catch (e) {
                
            }
        }
    },
}
</script>

<style lang="scss">
.shell-block {
    background: #736BF5;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>
