<template>
    <figure class="px-10 pt-10 pb-10">
        <h2 class="text-left">Your Public Documentation Site</h2>
        <h3 class="text-left mt-4 subpage-heading">Manage your Documentation with Dev-Docs</h3>
    </figure>


    <div ref="sectionholder" class="sections-holder">
        <div style="display: flex;" class="main-card p-5 rounded-[10px] border border-[#a2a1a5]/75">
            <div style="max-height: 30vh; max-width: 590.72px !important; border-radius: 0.625rem;
border: 2px solid #1C1C1C; display: grid;" class="overflow-hidden">
                <div style="max-width: 590.72px; max-height: 30vh;" class="relative">
                    <div v-if="repoData?.homepage" class="iframe-wrapper">
                        <iframe :src="repoData.homepage + '/docs'" title="W3Schools Free Online Web Tutorials"
                            class="iframe"></iframe>
                    </div>
                    <div v-else>
                        <img ref="iframeimg" :src="notDeployed" alt="placeholder" class="iframe-image" />
                        <div class="overlay-button">
                            <Button style="flex-grow: 0 !important;" @click="toggleDeployOptions">
                                <img :src="deployIcon" />
                                Deploy
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column;" class="justify-start items-start gap-10 ml-8 pl-8">
                <div class="w-full md:w-[541px] flex flex-col justify-start items-start gap-2.5">
                    <div class="flex flex-col justify-start items-start gap-2.5">
                        <div class="flex justify-start items-start gap-2.5">
                            <div class="grow text-white text-xl font-medium leading-[30px]">Your External Dev-Docs Repo
                                🚀</div>
                            <div class="ml-4">
                                <Tag v-if="repoData?.homepage" severity="success" value="Success">Project Deployed</Tag>
                                <Tag v-else severity="danger" value="Success">Project not Deployed</Tag>
                            </div>
                        </div>
                        <div class="text-[#a2a1a5] text-base font-medium leading-normal"><a :href="connectedGitUrl"
                                target="_blank">{{ connectedGitUrl }}</a></div>
                    </div>
                    <div class="flex flex-col justify-start items-start gap-[5px] mt-4">
                        <div class="text-[#a2a1a5] text-base font-medium leading-normal">Last updated</div>
                        <div v-if="repoData?.updated_at" class="text-white text-base font-medium leading-normal">{{
                            getTheLastEditedAt(repoData?.updated_at) }}</div>
                        <div v-else class="text-white text-base font-medium leading-normal">Never Edited</div>
                    </div>
                    <div class="flex flex-col justify-start items-start gap-[5px] mt-4">
                        <div class="text-[#a2a1a5] text-base font-medium leading-normal">Domain</div>
                        <div class="text-white text-base font-medium leading-normal"><a :href="repoData?.homepage"
                                target="_blank">{{ repoData?.homepage }}</a></div>
                    </div>
                </div>
                <div class="dashoptions flex justify-end items-center gap-2.5">
                    <Button style="white-space: nowrap;" severity="contrast" label="Github Setup" icon="pi pi-github" @click="githubToggle = true"
                        outlined />
                    <a style="white-space: nowrap;" class="p-button p-component p-button-contrast p-button-outlined"
                        :href="'https://codespaces.new/' + repoData?.full_name + '?quickstart=1 '" target="_blank"
                        rel="noopener">
                        <span class="p-button-icon p-button-icon-left pi pi-file-edit" data-pc-section="icon"></span>
                        Edit Docs
                    </a>
                    <Button style="white-space: nowrap;" severity="contrast" @click="generatePage = true" outlined>
                        <img :src="aiIcon" />
                        Generate new Docs
                    </Button>
                    <Button style="white-space: nowrap;" @click="toggleDeployOptions">
                        <img :src="deployIcon" />
                        Deploy
                    </Button>
                </div>
            </div>
        </div>
        <Popover class="popover-content" ref="deployToggle">
            <div class="card flex justify-center flex-wrap gap-4">
                <Button style="color: white;" outlined @click="pushToVercel">Deploy with Dev-Docs</Button><a class="p-button p-button p-button-outlined" style="display: inline-flex; color: white" target="_blank" :href="'https://vercel.com/new/import?s=' + connectedGitUrl"><img style="max-height: 20px; max-width: 20px" :src="vercelIcon"
                    alt="Deploy with your own Vercel" />Deploy with your own Vercel</a>
            </div>
        </Popover>
        <Popover class="popover-content"  ref="deployToggleIframe">
            <div class="card flex justify-center flex-wrap gap-4">
                <Button style="color: white;" outlined @click="pushToVercel">Deploy with Dev-Docs</Button><a class="p-button p-button p-button-outlined" style="display: inline-flex; color: white" target="_blank" :href="'https://vercel.com/new/import?s=' + connectedGitUrl"><img style="max-height: 20px; max-width: 20px" :src="vercelIcon"
                    alt="Deploy with your own Vercel" />Deploy with your own Vercel</a>
            </div>
        </Popover>
        <Dialog v-model:visible="githubToggle" modal>
            <github-tabs @updatechat="syncChatEdits" @customrepo="captureRepos" :chat-settings="chatSettings" />
        </Dialog>
        <Dialog modal header="Use AI To generate a new Page" :style="{ width: '80vw' }" v-model:visible="generatePage">
            
            <generate-page :repo="connectedGitUrl.split('https://github.com/')[1].split('.git')[0]" />
        </Dialog>
    </div>




    <github-drawer v-if="integrationDrawer" :open="integrationDrawer" title="github"
        @reset="integrationDrawer = false" />
</template>

<script>
import GithubDrawer from '@/components/GithubDrawer.vue';
import GithubTabs from '@/components/GithubPopup.vue';
import GeneratePage from '@/components/GenerateDocumentationPage.vue';
import Button from 'primevue/button';


import Popover from 'primevue/popover';

import {
    Setting, Edit
} from '@element-plus/icons-vue'

var cutItemsBy4 = function (bigarray) {
    var size = 3; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }

    return arrayOfArrays
}

const waitOneSecond = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 1000); // 1000 milliseconds = 1 second
    });
};



export default {
    data: () => ({
        githubToggle: false,
        sectionsGroups: null,
        org: null,
        generatePage: false,
        notDeployed: require("@/assets/not-deployed.png"),
        onboardingProgress: 1,
        integrationDrawer: false,
        docsUrl: null,
        activePanel: null,
        showEditUrl: false,
        newUser: "",
        githubInfo: null,
        repoData: null,
        vercelInfd: null,
        showConnectedGithub: false,
        connectedGitUrl: null,
        chatSettings: null,
        deployIcon: require("@/assets/deploy.svg"),
        githubIcon: require("@/assets/github.png"),
        chromeIcon: require("@/assets/chrome.png"),
        vercelIcon: require("@/assets/vercel.svg"),
        aiIcon: require("@/assets/ai.svg"),
        users: []
    }),
    components: {
        Setting,
        GithubDrawer,
        GithubTabs,
        Edit,
        Button,
        Popover,
        GeneratePage
    },
    methods: {
        syncChatEdits(val) {
            console.log("what is the val", val)
            this.chatSettings = val
        },
        async getAPIConfigs() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)
                var saveResponseOne = await fetch(`${url}/external_githubs?gpt=yes`, requestOptions)
                var jsonResponse = await saveResponseTwo.json()
                var jsonResponseOne = await saveResponseOne.json()
                console.log("what is the json response", jsonResponse.docs[0].content)
                this.connectedGitUrl = `https://github.com/${jsonResponse.docs[0].content.repo}`
            } catch (e) {

            }

        },
        findAndCenterButton() {
            const imgElement = this.$refs.iframeimg;
            if (imgElement) {
                const buttonElement = document.createElement('button');
                buttonElement.innerText = 'Click Me';
                buttonElement.style.position = 'absolute';
                buttonElement.style.left = '50%';
                buttonElement.style.top = '50%';
                buttonElement.style.transform = 'translate(-50%, -50%)';
                imgElement.parentNode.appendChild(buttonElement);
            }
        },
        getTheLastEditedAt(timestamp) {
            const givenDate = new Date(timestamp);

            // Get the current date
            const currentDate = new Date();

            // Calculate the difference in milliseconds
            const differenceInMs = currentDate - givenDate;

            // Convert milliseconds to days
            const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

            return `${differenceInDays} days ago`
        },
        async scrollToGetStarted() {
            this.activePanel = '0'
            await waitOneSecond()

            const container = this.$refs.sectionholder
            const element = this.$refs.setup

            if (container && element) {
                const elementPosition = element.offsetTop - container.offsetTop;
                container.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth'
                });
            }



            // const element = this.$refs.setup;
            // element.scrollIntoView({ behavior: 'smooth' });
        },
        toggleDeployOptions(event) {
            this.$refs.deployToggle.toggle(event);
            //this.$refs.githubToggle.show(event);
        },
        toggleIframeOptions(event) {
            console.log("YEBHHBSDHYGBFYUDGFUYGSUDY")
            //this.$refs.deployToggleIframe.toggle(event)
        },
        hideOverlay(event) {
            this.$refs.githubToggle.hide()
        },
        toggleGithubOptions() {
            this.$refs.githubToggle.show(event);
        },
        async getIntegrations() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)

                var response = await saveResponseTwo.json()

                var integration = response?.docs[0]
                this.githubInfo = integration
                const { content } = integration

                this.docsUrl = content?.url

                //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {

            }

        },
        async submitUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ github_user: user.name, url: this.docsUrl })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_docs_url`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {

            }

        },
        async checkForExistingGithub() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/created_repo`, requestOptions)

                var result = await saveResponseTwo.json()

                this.connectedGitUrl = result?.clone_url
                this.repoData = result
                if (!result?.clone_url) {
                    await this.createGithub()
                    await this.checkForExistingGithub()
                }
                this.users = result.users.filter(function (user) {
                    return !user.login.includes("avb")
                })
            } catch (e) {

            }

        },
        async checkForVercelUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

                if (result.status && result.exists) this.vercelUrl = `https://${org}-dev-docs.vercel.app`
            } catch (e) {

            }
        },
        async createGithub() {


            // this.updateAttributes({ saved: true })
            if (this.connectedGitUrl) {
                this.showConnectedGithub = true
                return
            }
            var myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/fork`, requestOptions)
                var result = await saveResponseTwo.json()

                // this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async addGithubUser() {


            // this.updateAttributes({ saved: true })

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = { name: this.newUser }


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_users`, requestOptions)
                var result = await saveResponseTwo.json()

                this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async pushToVercel() {
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                // const { content } = this.githubInfo
                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {
                console.log(e)
            }
        }
    },
    computed: {
        isPublished() {
            return this.$route.name == "published documentation"
        },
        progress() {
            return ``
        }
    },
    async mounted() {
        var sections = [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blog" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community" }]
        this.sectionsGroups = cutItemsBy4(sections)

        if (this.$route.params.org) this.org = this.$route.params.org
        await this.getIntegrations()
        await this.checkForExistingGithub()
        await this.checkForVercelUrl()
        await this.getAPIConfigs()
      
        // if(isAuthenticated == 'unauthenticated') {

        // }
    }

}

</script>

<style>


.flex-container {
    width: 100%;
    height: 100%;
}

.iframe-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.iframe-image {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
}

.iframe {
    width: 1420px;
    height: 1080px;
    border: none;
    overflow: hidden;
    transform: scale(0.416);
    transform-origin: 0 0;
}

.overlay-button {
    position: absolute;
    border-radius: 0.625rem;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(3px);
    display: flex;
    z-index: 10;
    top: 0;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-content: center;
    align-items: center;
}



/* Optional: Add blur effect to iframe */

.not-deployed-button {
    position: absolute !important;
    top: 50%;
    left: 280px;
    transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}


.sections-holder {
    padding: 2em;
}

.external-docs-grid {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);


    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 2em;
    min-height: 80ch;
}

.guides-content {
    background: #2C2738 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.apps-content {
    background: #14A38B !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.concepts-content {
    background: #736BF5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.blogs-content {
    background: #FAB5B5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.api-content {
    background: #409EFF !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.community-content {
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
}
</style>