<template>
    <el-dialog v-model="dialogFormVisible" closed="closeDialog" title="Set your styles">
        <editor-content autocorrect="off" autocapitalize="off" spellcheck="false" class="style-external-editor"
            :editor="editor" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button @click="saveContent()">Publish</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { lowlight } from 'lowlight'
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import Dropcursor from '@tiptap/extension-dropcursor'
import FormulaComponent from './custom_extensions/FormulaExtension.js'
import FormulaMark from './custom_extensions/FormulaMark.js'
import ReferenceMark from './custom_extensions/ReferenceWord.js'
import Commands from './custom_extensions/commands'
import suggestion from './custom_extensions/suggestion'

export default {
    props: {
        show: Boolean
    },
    components: {
        EditorContent,
        FloatingMenu,
    },
    data: () => ({
        dialogFormVisible: false,
        editor: null
    }),
    unmounted() {

        this.$emit("closestyle")
    },
    methods: {
        async getData() {
            const component = this

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var response = await fetch(`${url}/external_page?path=src/css/custom.scss`, requestOptions)
            var jsonResponse = await response.json()
            
            var stylingContent = atob(jsonResponse?.docs?.content, 'base64')
            
            this.editor.commands.setContent(`<pre><code class="language-scss">${stylingContent}</code></pre>`)
        },
        closeDialog() {
            this.$emit("closestyle")
        },
        async saveContent() {
            
            var data = this.editor.getJSON()
            
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, title: this.title, tags: this.tags || [], user: org, section: this.section }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_styling`, requestOptions)
            } catch (e) {
                
            }
        }
    },
    async mounted() {
        const component = this
        this.dialogFormVisible = this.show
        this.editor = new Editor({
            editorProps: {
                handleClickOn: (view, pos, node) => {
                    // 
                    // 
                    // 
                    // 
                    // if (node?.attrs?.language != "scss") component.editor.setEditable(false)
                    // 
                    // 
                    // component.editor.setEditable(false)

                }
            },
            extensions: [
                Document,
                Paragraph,
                Text,
                StarterKit,
                TaskList,
                Dropcursor,
                FormulaComponent,
                Commands.configure({
                    suggestion,
                }),
                FormulaMark,
                ReferenceMark,
                MermaidBlock.configure({ saved: "yeah yeah" }),
                ResizableImage.configure({ allowBase64: true, inline: true }),
                TaskItem.configure({
                    nested: true,
                }),
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight, exitOnArrowDown: false }),
            ],
            content: `
            <pre>
            <code class="language-scss">

            .themedImage_ToTc {
                content:url("https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/new%20dev-docs%20logo%20white%20text%20(4).png?v=1668386911915");
            }

            :root {
                --ifm-color-primary: #4e00f5;
                --ifm-color-primary-dark: #4600dd;
                --ifm-color-primary-darker: #4200d0;
                --ifm-color-primary-darkest: #3700ab;
                --ifm-color-primary-light: #5b0fff;
                --ifm-color-primary-lighter: #631bff;
                --ifm-color-primary-lightest: #7c40ff;
                --ifm-code-font-size: 95%;
                --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.1);
            }
            
            /* For readability concerns, you should choose a lighter palette in dark mode. */
            [data-theme='dark'] {
                --ifm-color-primary: #9666ff;
                --ifm-color-primary-dark: #7d42ff;
                --ifm-color-primary-darker: #7130ff;
                --ifm-color-primary-darkest: #4e00fa;
                --ifm-color-primary-light: #ae8aff;
                --ifm-color-primary-lighter: #bb9cff;
                --ifm-color-primary-lightest: #dfd1ff;
                --ifm-background-color: #000000;
                --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.3);
            }

            .ProseMirror {
                > * + * {
                margin-top: 0.75em;
                }
            
                ul,
                ol {
                padding: 0 1rem;
                }
            
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                line-height: 1.1;
                }
            
                code {
                background-color: rgba(#616161, 0.1);
                color: #616161;
                }
            
                pre {
                background: #0D0D0D;
                color: #FFF;
                font-family: 'JetBrainsMono', monospace;
                padding: 0.75rem 1rem;
                border-radius: 0.5rem;
            
                code {
                    color: inherit;
                    padding: 0;
                    background: none;
                    font-size: 0.8rem;
                }
                }
            
                img {
                max-width: 100%;
                height: auto;
                }
            
                blockquote {
                padding-left: 1rem;
                border-left: 2px solid rgba(#0D0D0D, 0.1);
                }
            
                hr {
                border: none;
                border-top: 2px solid rgba(#0D0D0D, 0.1);
                margin: 2rem 0;
                }
            }

            </code>
            </pre>`,
        })
        await this.getData()
        var elements = document.getElementsByClassName("code-block");

        var setEdit = function () {
            component.editor.setEditable(true)
        };

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', setEdit, false);
        }

    }
}

</script>

<style lang="scss">
.style-external-editor {
    background-color: black;

    pre {
        min-width: 100%;
    }
}
</style>