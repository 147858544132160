<template>
    <div>
        <Dialog v-if="drawer && title === 'proxy url'" v-model:visible="drawer" :header="title" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="proxyUrlForm.name" />
                </div>
                <div class="p-field">
                    <label for="url">URL</label>
                    <InputText id="url" v-model="proxyUrlForm.url" />
                </div>
                <div class="p-field">
                    <Button label="Submit" @click="submitFormData()" />
                </div>
            </div>
        </Dialog>
        <Dialog v-else v-model:visible="drawer" :header="title" :modal="true">
            <TabView v-model="activeName" class="demo-tabs" @tab-change="handleClick">
                <TabPanel header="Setup">
                    <div class="p-fluid">
              
     
                        <div v-for="(item, index) in formItems" :key="index" class="p-field">
                            <label :for="item.key">{{ item.title }}</label>
                            <InputText :id="item.key" :type="item.type" v-model="form_data[item.key]" />
                        </div>
                        <div class="p-field">
                            <Button label="Submit" @click="submitFormData()" />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Codebases">
                    <codebase-cards v-if="title" :integration="title" />
                </TabPanel>
            </TabView>
        </Dialog>
    </div>
</template>

<script>

import CodebaseCards from './CodebaseIntegrationCards.vue';

export default {
    props: {
        open: Boolean,
        title: String,
        reference: String,
    },
    components: {
        CodebaseCards,
    },
    methods: {
        async getProxyUrl() {
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/proxy_url`, requestOptions)
                var result = await saveResponseTwo.json()
                
                var {name, id, url} = result[0]
                this.proxyUrlForm.name = name
                this.proxyUrlForm.url = url
                // 
                // var response = await saveResponseTwo.json()
                // 
                // var integration = response?.docs[0] 
                // 
                // this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {
                
            }

        },
        async getIntegrations() {
            if(this.title == "proxy url") return await this.getProxyUrl()
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/intergrations?searchKey=name&searchValue=${this.title}&decrypt=yes`, requestOptions)
                
                var response = await saveResponseTwo.json()
                
                var integration = response?.docs[0] 
                
                this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {
                
            }

        },
        handleClose() {
            this.$emit("reset")
        },
        async createProxyUrl() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                var raw = JSON.stringify({...this.proxyUrlForm})
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url`, requestOptions)
            } catch (e) {
                
            }

        },
        async submitFormData() {
            if(this.title == "proxy url") return await this.createProxyUrl()
            
            
            var postBodyObject = {...this.form_data}
            // for(const formItem of this.formItems) {
            //     postBodyObject[formItem.key] = formItem.value
            // }


       
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({name: this.title, ...postBodyObject});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/intergrations`, requestOptions)
            } catch (e) {
                
            }
        },
    },
    data: () => ({
        drawer: null,
        formItems: null,
        form_data: { user: 'boogie' },
        proxyUrlForm: {
            url: '',
            name: '',
        },
        form_schemas: {
            confluence: [
                { title: 'Confluence Username', details: '', type: 'text', key: 'user', value: '' },
                { title: 'Confluence API Token', details: '', type: 'password', key: 'api_token', value: '' },
                { title: 'Confluence Base Url', details: '', type: 'text', key: 'url', value: '' },
            ],
            'proxy url': [
                { title: 'Proxy Server Name', details: '', type: 'text', key: 'name', value: '' },
                { title: 'Proxy Server Url', details: '', type: 'url', key: 'url', value: '' },
            ],
        },
    }),
    async mounted() {
        this.drawer = this.open
        this.formItems = this.form_schemas[this.title]
        await this.getIntegrations()
        // ...existing mounted logic...
    },
    unmounted() {
        // ...existing unmounted logic...
    },
};
</script>

<style>
.p-field {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

</style>