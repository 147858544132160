<template>
    <figure class="px-10 pt-10 pb-10">
        <h3 class="text-left">External Documentation</h3>
        <h1 class="text-left">Your Dev-Docs</h1>

    </figure>
    <div class="sections-holder">
        <div class="external-docs-grid" v-if="sectionsGroups">
            <!-- <figure v-if="docsUrl">
                <h2>Your Docs Are live at: <el-link type="primary" :underline="true" :href="docsUrl">{{ docsUrl
                }}</el-link><span style="padding-left: 0.5em;"><el-button @click="showEditUrl = true"
                            circle><el-icon>
                                <Edit />
                            </el-icon></el-button></span></h2>
            </figure>
            <figure class="mt-2" v-else>
                <h2>Add your your url:<span style="padding-left: 0.5em;"><el-button @click="showEditUrl = true"
                            circle><el-icon>
                                <Edit />
                            </el-icon></el-button></span></h2>
            </figure> -->
            <figure class="mt-4" v-if="githubInfo?.content?.repo">
                <h3>Docs Are synced with: <el-link type="primary" :underline="true"
                        :href="'https://github.com/' + githubInfo?.content?.repo">{{ githubInfo?.content?.repo }}</el-link>
                </h3>
            </figure>
            <el-row>
                <el-col :span="24">
                    <figure class="mt-2">
                        <el-row>
                            <el-col :span="20">
                                <h1>Your External Dev-Docs Repo 🚀</h1>
                            </el-col>
                            <el-col :span="4">
                                <el-button @click="integrationDrawer = true" circle><el-icon>
                                        <Setting />
                                    </el-icon></el-button>

                            </el-col>
                        </el-row>
                    </figure>
                    <h3 class="mt-2" v-if="connectedGitUrl">Your Repo: {{ connectedGitUrl }}</h3>
                    <el-link class="mt-2" v-if="connectedGitUrl" :href="connectedGitUrl" type="primary"
                        target="_blank">Access Your Github Repo</el-link>
                        <el-link class="mt-2 ml-2" v-if="repoData?.full_name"
                        :href="'https://codespaces.new/' + repoData?.full_name + '?quickstart=1 '"
                        target="_blank">Edit Your Docs</el-link>
                    <div v-if="connectedGitUrl" class="mt-10 card-actions justify-end">
                        <el-button @click="pushToVercel">Deploy via Dev-Docs</el-button>
                        <a :href="'https://vercel.com/new/import?s=' + connectedGitUrl"><img src="https://vercel.com/button"
                                alt="Deploy with your own Vercel" /></a>
                    </div>
                    <el-skeleton v-else :rows="1" animated />
                    <!-- <h2>Edit Your Docs</h2>
                    <el-link v-if="repoData?.full_name"
                        :href="'https://codespaces.new/' + repoData?.full_name + '?quickstart=1 '"
                        target="_blank">Edit Your Docs</el-link>
                    <el-skeleton v-else :rows="1" animated /> -->
                    <el-card class="mt-2" v-if="repoData?.homepage">
                        <h2>vercel url</h2>
                        <el-link v-if="repoData?.homepage"
                        :href="repoData?.homepage"
                        target="_blank">{{ repoData?.homepage }}</el-link>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
    <github-drawer v-if="integrationDrawer" :open="integrationDrawer" title="github" @reset="integrationDrawer = false" />
</template>

<script>
import GithubDrawer from '@/components/GithubDrawer.vue';
import {
    Setting, Edit
} from '@element-plus/icons-vue'

var cutItemsBy4 = function (bigarray) {
    var size = 3; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }

    return arrayOfArrays
}


export default {
    data: () => ({
        sectionsGroups: null,
        org: null,
        integrationDrawer: false,
        docsUrl: null,
        showEditUrl: false,
        newUser: "",
        githubInfo: null,
        repoData: null,
        vercelInfd: null,
        showConnectedGithub: false,
        connectedGitUrl: null,
        users: []
    }),
    components: {
        Setting,
        GithubDrawer,
        Edit
    },
    methods: {
        async getIntegrations() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)

                var response = await saveResponseTwo.json()

                var integration = response?.docs[0]
                this.githubInfo = integration
                const { content } = integration

                this.docsUrl = content?.url

                //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {

            }

        },
        async submitUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ github_user: user.name, url: this.docsUrl })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_docs_url`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {

            }

        },
        async checkForExistingGithub() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/created_repo`, requestOptions)

                var result = await saveResponseTwo.json()

                this.connectedGitUrl = result?.clone_url
                this.repoData = result
                if (!result?.clone_url) {
                    await this.createGithub()
                    await this.checkForExistingGithub()
                }
                this.users = result.users.filter(function (user) {
                    return !user.login.includes("avb")
                })
            } catch (e) {

            }

        },
        async checkForVercelUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

                if (result.status && result.exists) this.vercelUrl = `https://${org}-dev-docs.vercel.app`
            } catch (e) {

            }
        },
        async createGithub() {


            // this.updateAttributes({ saved: true })
            if (this.connectedGitUrl) {
                this.showConnectedGithub = true
                return
            }
            var myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/fork`, requestOptions)
                var result = await saveResponseTwo.json()

                // this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async addGithubUser() {


            // this.updateAttributes({ saved: true })

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = { name: this.newUser }


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_users`, requestOptions)
                var result = await saveResponseTwo.json()

                this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async pushToVercel() {
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                // const { content } = this.githubInfo
                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {
                console.log(e)
            }
        }
    },
    computed: {
        isPublished() {
            return this.$route.name == "published documentation"
        }
    },
    async mounted() {
        var sections = [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blog" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community" }]
        this.sectionsGroups = cutItemsBy4(sections)

        if (this.$route.params.org) this.org = this.$route.params.org
        await this.getIntegrations()
        await this.checkForExistingGithub()
        await this.checkForVercelUrl()
    }

}

</script>

<style>
.sections-holder {
    padding: 2em;
}

.external-docs-grid {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 2em;
    min-height: 80ch;
}

.guides-content {
    background: #2C2738 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.apps-content {
    background: #14A38B !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.concepts-content {
    background: #736BF5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.blogs-content {
    background: #FAB5B5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.api-content {
    background: #409EFF !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.community-content {
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
}</style>