<template>
    <floating-menu class="menu-one" :editor="editor" :shouldShow="shouldShow"
        :tippy-options="{ duration: 10, onClickOutside:  (instance, event) => { instance.hide() } }"
        v-if="editor && toggleFloatingMenu">
        <el-button v-if="!editor.isActive('column')" style="background-color: #626aef !important; color: white !important"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            H1
        </el-button>
        <el-button v-if="!editor.isActive('column')" style="background-color: #626aef !important; color: white !important"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            H2
        </el-button>
        <el-button style="background-color: #626aef !important; color: white !important"
            @click="showTemplatesDialog = true">Templates</el-button>
        <el-button v-if="!editor.isActive('column')" style="background-color: #626aef !important; color: white !important"
            @click="editor.chain().focus().setHorizontalRule().run()">
            setHorizontalRule
        </el-button>
        <el-dropdown class="ml-2" trigger="click">
            <el-button style="background-color: #626aef !important; color: white !important">
                Table
            </el-button>
            <template #dropdown>
                <el-dropdown-menu style="max-height: 20vh; overflow-y: scroll;">
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
                            Insert Table
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().addColumnBefore().run()"
                            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            Add Column Before
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().addColumnAfter().run()">
                            Add Column After
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button class="btn-editor" style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().deleteColumn().run()">
                            Delete Column
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().addRowBefore().run()">Add
                            Add Row Before</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().addRowAfter().run()">Add Row After</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().deleteRow().run()">Delete Row</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().deleteTable().run()">Delete Table</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="eeditor.chain().focus().mergeCells().run()">Mege Cells</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().splitCell().run()">Split Cell</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().toggleHeaderColumn().run()">Toggle Column</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().toggleHeaderRow().run()">Toggle Header Row</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().toggleHeaderCell().run()">Toggle Header Cell</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().mergeOrSplit().run()">Merge or Split</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().fixTables().run()">Fix Tables</el-button>
                    </el-dropdown-item>


                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <el-dropdown class="ml-2" trigger="click">
            <el-button style="background-color: #626aef !important; color: white !important">
                More Actions 🤖
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                            H1
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            H2
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="insertAiBlock()">
                            Insert AI Block
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="addMermaidBlock()">
                            Add Mermaid Diagram
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button class="btn-editor" style="background-color: #626aef !important; color: white !important"
                            @click="insertCardWithColumn()" :class="{ 'is-active': editor.isActive('formulaNode') }">
                            Math block
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="showImport = true">Add
                            Import</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="showMisc = true">Add
                            Misc</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="addDraggableCard()">Add Card</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="addButton()">Add Button</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="insertStyle()">Add Style</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="addGrid">Create Grid!</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button style="background-color: #626aef !important; color: white !important"
                            @click="insertIframe">Insert Iframe</el-button>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>

    </floating-menu>
    <bubble-menu class="bubble-menu" :element="menu" :tippy-options="{ duration: 100 }" v-if="editor" :editor="editor">
        <input type="color" @input="editor.chain().focus().setColor($event.target.value).run()"
            :value="editor.getAttributes('textStyle').color">
        <el-popover placement="top" :width="400" trigger="click">
            <template #reference>
                <el-button>Size</el-button>
            </template>
            <el-input-number v-model="fontSize" :min="1" :max="100" />
            <el-button style="background-color: #626aef !important; color: white !important" @click="applyFontSize()">Apply
                Font Size</el-button>
        </el-popover>
        <el-button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            Bold
        </el-button>
        <el-button @click="editor.chain().unsetAllMarks().run()">
            Clear Styles
        </el-button>
        <ai-text-popover :editor="editor" />
        <el-dropdown class="ml-2" trigger="click">
            <el-button>
                Position
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item>
                        <el-button @click="editor.chain().focus().setTextAlign('left').run()"
                            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                            left
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="editor.chain().focus().setTextAlign('center').run()"
                            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                            center
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="editor.chain().focus().setTextAlign('right').run()"
                            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                            right
                        </el-button>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <el-dropdown class="ml-2" trigger="click">
            <el-button>
                More Actions
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item>
                        <el-button @click="editor.chain().focus().toggleItalic().run()"
                            :class="{ 'is-active': editor.isActive('italic') }">
                            Italic
                        </el-button>

                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="editor.chain().focus().toggleStrike().run()"
                            :class="{ 'is-active': editor.isActive('strike') }">
                            Strike
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="openSetLink()" :class="{ 'is-active': editor.isActive('strike') }">
                            Set Link
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="addButtonClass()">
                            Set Link Button
                        </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button @click="addCustomStyling">
                            Add Custom Styling
                        </el-button>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>



    </bubble-menu>
    <el-dialog v-model="saveFormVisible" title="Save new Page">
        <el-form>
            <el-form-item label="Page Name" :label-width="formLabelWidth">
                <el-input v-model="title" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Section Type" :label-width="formLabelWidth">
                <el-select v-model="section" placeholder="Please select a section">
                    <el-option v-for="(section, index) of sectionOptions" :label="section.label" :value="section.value" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="saveFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="saveContent()">Save</el-button>
            </span>
        </template>
    </el-dialog>
    <el-row class="editor-save-actions mt-8 pt-2 pb-2">
        <el-col :span="2">
            <el-button @click="openDocsNav()">
                <el-icon>
                    <Expand />
                </el-icon>
            </el-button>
        </el-col>
        <el-col :span="17">
            <h2>Create Doc</h2>
        </el-col>
        <el-col class="flex" :span="4">
            <el-button @click="preview()" color="#626aef">Preview</el-button>
            <el-button @click="saveFormVisible = true" color="#626aef">Save 💾</el-button>
            <el-button @click="deleteContent()" color="red">
                Delete 🗑
            </el-button>
            <el-button color="#7303c0" @click="publishContent()">
                Publish 📰
            </el-button>
        </el-col>
        <el-col :span="1"></el-col>
    </el-row>
    <el-row ref="editorviewer" class="editing-external-editor">
        <el-col :span="3"></el-col>
        <el-col :span="18">
            <grammarly-editor-plugin v-if="!fullEditor" clientId="client_P9QvKd1kU63i6XeSWimw7S">
                <editor-content @drop="dropHandler" @dragstart="dragOverHandler" class="editing-external-editor"
                    :editor="editor" />
            </grammarly-editor-plugin>
        </el-col>
        <el-col :span="3">


            <div class="overlay-logo">
                <el-popover :visible="roboMessage" placement="left" title="Heads Up" :width="200">
                    <template #reference>

                        <img @click="roboMessage = true" class="toggle-button"
                            src="https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/new%20dev-docs%20logo%20white%20text%20(4).png?v=1668386911915"
                            alt="Circular button" />
                    </template>
                    <span>Hey heads up!
                        <ul>
                            <li><strong v-if="editMode">Edit Mode is On </strong><strong v-else>Typing is On </strong></li>
                            <li><strong>{{ toggleFloatingMenu ? "Action menu is on" : "Action Menu is off" }}</strong></li>
                        </ul>
                    </span>
                </el-popover>

            </div>

        </el-col>

    </el-row>
    <reference-drawer v-if="openReference" :open="openReference" @reset="openReference = false" />
    <documents-nav v-if="showDocsNav" :title="'whatever'" :dialog="showDocsNav" @loaded_doc="loadDoc"
        @close="showDocsNav = false" />

    <misc-doc-selector v-if="showMisc" :show="showMisc" @insert="insertMisc" @close="showMisc = false" />
    <import v-if="showImport" :show="showImport" @insert="insertImport" @close="showImport = false" />
    <add-element-dialog v-if="addElementDialog" :text="selectedText" :collected="collectedStyles" :show="addElementDialog" @createelement="createElement"
        @close="addElementDialog = false" />
    <templates-dialog v-if="showTemplatesDialog" :show="showTemplatesDialog" @insert="insertTemplate" @close="showTemplatesDialog = false" />
    <el-dialog v-model="aLinkDialog">
        <el-form>
            <el-form-item label="Insert Your Link">
                <el-input type="text" v-model="aLink" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="setLink()">Insert
                    Set Link</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="deleteColumnDialog">
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="deleteColumnDialog = false">Delete Columns</el-button>
                <el-button type="primary" @click="unsetCoumns()">Delete Columns</el-button>
            </span>
        </template>

    </el-dialog>
    <el-dialog :fullscreen="true" v-model="fullEditor">
        <Preview v-if="previewContent && fullEditor" :content="previewContent" />
    </el-dialog>    
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, BubbleMenu } from '@tiptap/vue-3'
import MiscDocSelector from './MiscSelector.vue'
import Import from './Import.vue'
import { Heading } from '@tiptap/extension-heading'
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-vue'
import ReferenceDrawer from './ReferenceDrawer.vue'
import { Expand } from '@element-plus/icons-vue'
import DocumentsNav from './DocumentsNav.vue'
import { Menu } from '@element-plus/icons-vue'
import addElementDialog from './addElementDialog.vue'
import TemplatesDialog from './TemplatesDialog.vue'
import blockTemplates from '@/blockTemplates/blockTemplates'
import Preview from './Preview.vue'
import { findGithubImagePath } from '@/helper_functions/imageHelper'
import AiTextPopover from './AiTextPopover.vue'

const reader = new FileReader();
import editorConfig from '@/editorConfiguration/editorConfigs'
import parser from "@/plugins/parser"

const markdownParser = new parser()
export default {
    components: {
        EditorContent,
        FloatingMenu,
        ReferenceDrawer,
        Preview,
        DocumentsNav,
        TemplatesDialog,
        addElementDialog,
        Expand,
        MiscDocSelector,
        Import,
        BubbleMenu,
        GrammarlyEditorPlugin,
        AiTextPopover,
        Menu
    },
    watch: {
        toggleFloatingMenu(val) {
            
            this.roboMessage = true
        },
        roboMessage(val) {
            const component = this
            if (val) {
                setTimeout(() => {
                    this.roboMessage = false
                }, 2000);
            }
        }
    },
    data: () => ({
        editor: null,
        title: "",
        toggleFloatingMenu: false,
        showDocsNav: false,
        previewContent: null,
        openReference: false,
        existingDocument: null,
        saveFormVisible: false,
        fullEditor: false,
        showTemplatesDialog: false,
        fontSize: 0,
        tippyColumnOptions: null,
        hideFloatingMenu: false,
        editMode: false,
        addElementDialog: false,
        roboMessage: false,
        showMisc: false,
        sectionOptions: [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blog" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community content" }],
        section: "guides",
        showImport: false,
        aLinkDialog: false,
        deleteColumnDialog: false,
        columnNumber: 1,
        aLink: "",
        selectedText: null,
        collectedStyles: []
    }),
    methods: {
        async checkExistingContent() {
            if (this.existingContent) {
                const pasrseHtml = markdownParser.convertToHTML(this.existingContent)
                this.editor.commands.setContent(pasrseHtml)
            } else {
                var route = this.$route
                if (!route?.params?.filter) return
                var filter = decodeURI(route?.params?.filter)
                
                await this.getExistingContent(filter)
            }
        },
        insertStyle() {
            this.editor.commands.insertContent(`<style>body {background: red;}</style>`)
        },
        insertIframe() {
            this.editor.commands.insertContent(`<iframe-block></iframe-block>`)
        },
        openSetLink() {
            const previousUrl = this.editor.getAttributes('link').href
            this.aLink = previousUrl || ""
            this.aLinkDialog = true
        },
        addCustomStyling() {
            
            const { state } = this.editor
            const { selection } = state
            const { from, to } = selection
            const text = state.doc.textBetween(from, to, ' ')
            this.editor.commands.setMark("customCssMark")
            this.selectedText = text
            //this.editor.commands
            this.addElementDialog = true
        },
        setLink() {
            const url = this.aLink
            if (url === null) {
                return
            }

            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()

        },
        addMermaidBlock() {
            this.editor.commands.insertContent(`<mermaid-block></mermaid-block>`)
        },
        async addMiscDoc() {
            
        },
        async addGrid() {
            this.editor.commands.insertContent(blockTemplates.titleGrid)
        },
        async openDocsNav() {
            this.showDocsNav = true
        },
        insertTemplate(val) {
            
            this.editor.commands.insertContent(val)
        },
        async loadDoc(val) {
            window.alert("HERE")
            
            
            this.title = val.title.split(".md")[0]
            
            const parsedHtml = markdownParser .convertToHTML(val.documentData)
            this.editor.commands.setContent(parsedHtml)
        },
        async insertMisc(val) {
            this.editor.commands.insertContent(val)
            this.showMisc = false
        },
        async insertImport(val) {
            this.editor.commands.insertContent(val)
        },
        addDraggableCard() {
            this.editor.commands.insertContent(`
        <div data-type="draggable-item" style="color: red;">
          <p>Your Content goes here!</p>
        </div>`)
        },
        async insertAiBlock() {
            this.editor.commands.insertContent(`<ai-block></ai-block>`)
        },
        async getExistingContent(filter) {
            try {
                var myHeaders = new Headers();
                var token = await this.$authInstance.getToken()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var url = await this.$authInstance.getBaseUrl()
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var responseData = await fetch(`${url}/external_docs?searchKey=filter&searchValue=${filter}`, requestOptions)
                
                var resJson = await responseData.json()
                
                if (!resJson?.data[0]) throw Error("No matching external page")
                this.existingDocument = resJson?.data[0]
                this.title = this.existingDocument.title
                this.section = this.existingDocument.section
                
                this.editor.commands.setContent(this.existingDocument.content)

            } catch (e) {
                
            }

        },
        createElement(val) {
            
            this.collectedStyles.push({title: this.selectedText, ...val})
            this.editor.commands.setMark("customCssMark", { class: val.classes, style: val.style })
        },
        applyFontSize() {
            this.editor.commands.setMark("customCssMark", { style: `font-size: ${this.fontSize}px` })
        },
        addButtonClass() {
            //this.editor.commands.setMark("link", { class: 'button-link' })
            this.editor.commands.insertContent(`<a customStyleString="color: green !important" href='https://google.com'>hey</a>`)
        },
        addList() {
            this.editor.commands.insertContent(`
        <ul>
          <li>A list item</li>
          <li>And another one</li>
        </ul>
      `)
        },
        insertCardWithColumn() {
            const htmlString = `
            <div class="column-block">
                <div class="column">
                    <h1>
                        <span style="color: #ffffff">Guides</span><br>
                    </h1>
                </div>
                <div class="column">
                    <h1>
                        <span style="color: #ffffff">Guides</span><br>
                    </h1>
                </div>
            </div>`
            this.editor.commands.insertContent(htmlString)
        },
        async preview() {
            var html = await this.editor.getHTML()
            
            this.previewContent = html
            this.fullEditor = true
        },
        async saveContent() {
            const component = this
            
            var data = this.editor.getJSON()
            var html = findGithubImagePath(component.editor.getHTML())
            var text = this.editor.getText()
            
            

            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(html)
                var request_body = { content: contentAsString, text: text, title: this.title, tags: this.tags || [], user: org, section: this.section }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };


                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                if (this.existingPath) {
                    const payload = request_body
                    
                    // payload[this.dataKey] = data
                    payload["specific_path"] = this.existingPath
                    payload["page"] = true
                    requestOptions.body = JSON.stringify(payload)
                    return await fetch(`${url}/external_page`, requestOptions)
                } else await fetch(`${url}/external_page`, requestOptions)

            } catch (e) {
                
            }
            this.saveFormVisible = false
        },
        async deleteContent() {
            
            var data = this.editor.getJSON()
            var html = this.editor.getHTML()
            
            
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, html: html, title: this.title, tags: this.tags || [], user: org, section: this.section }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/delete_external_page`, requestOptions)

            } catch (e) {
                
            }
            this.saveFormVisible = false
        },
        async publishContent() {
            
            var data = this.editor.getJSON()
            
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                const filter = `${this.section}-${this.title}`
                var request_body = { searchKey: "filter", searchValue: filter }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponse = await fetch(`${url}/external_doc/publish`, requestOptions)
                var jsonResponse = await saveResponse.json()
                
            } catch (e) {
                
            }
        },
        clickReference() {
            this.openReference = true
        },
        shouldShow: ({ editor, view, state, oldState }) => {
            if (editor.isActive("column")) return editor?.isEditable
            else return editor.isActive("paragraph") && editor?.isEditable
        },
        async toggleCoumns() {
            const component = this
            await this.editor.chain().setColumns(component.columnNumber).run()
        },
        async unsetCoumns() {
            await this.editor.chain().unsetColumns().run()
            this.deleteColumnDialog = false
        },
        dragOverHandler(ev) {
            

            // Prevent default behavior (Prevent file from being opened)
            // ev.preventDefault();
        },
        floatingMenuShow({ editor, view, state, oldState }) {
            return this.deleteColumnDialog
        },
        insertAiBlock() {
            this.editor.commands.insertContent(`<ai-block></ai-block>`)
        },
        addButton() {
            //this.editor.commands.insertContent("<button>hey</button>")
            this.editor.commands.insertContent(`<a customStyleString="color: green !important" href='https://google.com'>hey</a>`)
        },
        dropHandler(ev) {
            
            ev.preventDefault();

            // Prevent default behavior (Prevent file from being opened)


            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...ev.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === 'file') {
                        const file = item.getAsFile();
                        
                        reader.readAsDataURL(file);
                        var fileUrl = URL.createObjectURL(file);
                        
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...ev.dataTransfer.files].forEach((file, i) => {
                    
                    reader.readAsDataURL(file);
                    var fileUrl = URL.createObjectURL(file);
                    
                });
            }
        },

    },
    props: {
        existingContent: Object,
        existingPath: String,
        extraBodyItems: Object,
        dataKey: String
    },
    unmounted() {
        localStorage.removeItem("tree_data")
    },
    async mounted() {
        localStorage.setItem("edit", JSON.stringify({ mode: false }))
        var component = this
        this.editor = new Editor(editorConfig)


        reader.addEventListener("load", () => {
            
            // convert image file to base64 string
            component.editor.commands.insertContent(`<img src="${reader.result}" external="yes">`,
                {
                    parseOptions: {
                        preserveWhitespace: true,
                    }
                })
        }, false);

        this._keyListener = async function (e) {
            if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                if (component.existingPath) await component.saveContent()
                else component.saveFormVisible = true
                //await this.saveContent()
                //this.saveNote();
            } if (e.key === "h" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                component.toggleFloatingMenu = !component.toggleFloatingMenu
            }
        };

        document.addEventListener('keydown', this._keyListener.bind(this));
        await this.checkExistingContent()
        await this.editor.commands.focus('end')
    }
}
</script>

<style lang="scss">
@import "~katex/dist/katex.css";




.bubble-menu {
    min-width: 30vw !important;
    overflow-x: scroll !important;
}

// .editor-save-actions {}

// .docs-notification {
//     background-color: #2C2738 !important;
//     background: #2C2738 !important;
// }

// .commit-button {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// .ProseMirror-gapcursor:after {
//     border-top: 1px solid white !important;
// }


// .bubble-menu {
//     display: flex;
//     background: #2C2738 !important;
//     padding: 0.2rem;
//     border-radius: 0.5rem;
//     overflow-x: scroll;

//     button {
//         border: none;
//         background: none;
//         color: white !important;
//         font-size: 0.85rem;
//         font-weight: 500;
//         padding: 0.2rem;

//         opacity: 0.6;

//         &:hover,
//         &.is-active {
//             opacity: 1;
//         }
//     }
// }

// .menu-one {
//     padding-top: 5em;
//     margin-left: 0em !important;
//     display: flex !important;
//     justify-content: space-around !important;
//     padding-left: 10em;
// }

// ul[data-type="taskList"] {
//     list-style: none;
//     padding: 0;

//     p {
//         margin: 0;
//     }

//     li {
//         display: flex;

//         >label {
//             flex: 0 0 auto;
//             margin-right: 0.5rem;
//             user-select: none;
//         }

//         >div {
//             flex: 1 1 auto;
//         }
//     }
// }

// .arch-diagram {
//     background: red;
// }

// img[title="arch-diagram"] {
//     background: red;
// }



// .has-focus {
//     border-radius: 3px;
//     box-shadow: 1px 0 5px -1px #888;
// }

// .editor-box {
//     //padding: 1em;
// }

// .editing-external-editor span[data-inline-katex] {
//     background: rgb(255, 252, 196);
//     padding: 2px;
//     display: inline-block;
// }

// /* Basic editor styles */
// .editing-external-editor {
//     background: #121220 !important;



//     a {
//         color: #68CEF8;
//         cursor: pointer;
//     }

//     [data-inline-reference="true"] {
//         cursor: pointer;
//         padding: 0px;
//         display: inline;
//         font-weight: bold;
//         border: 0;
//         margin: 0 1px;
//         color: rgb(227, 68, 242);
//         width: auto;
//         height: auto;
//         box-sizing: content-box;
//         /* Styles */
//     }

//     .button-link {
//         padding-top: 0.05em;
//         padding-bottom: 0.05em;
//         padding-left: 0.5em;
//         padding-right: 0.5em;
//         backdrop-filter: blur(16px) saturate(180%);
//         -webkit-backdrop-filter: blur(16px) saturate(180%);
//         background-color: rgba(69, 69, 177, 0.75);
//         border-radius: 12px;
//         border: 1px solid rgba(255, 255, 255, 0.125);
//         color: white;
//     }

//     .tableWrapper {
//         padding: 1em !important;
//         overflow-x: auto;
//     }

//     .resize-cursor {
//         cursor: ew-resize;
//         cursor: col-resize;
//     }

//     .ProseMirror {
//         table {
//             padding: 1em !important;
//             border-collapse: collapse;
//             table-layout: fixed;
//             width: 100%;
//             margin: 0;
//             overflow: hidden;
//             backdrop-filter: blur(16px) saturate(180%);
//             -webkit-backdrop-filter: blur(16px) saturate(180%);
//             background-color: rgba(17, 25, 40, 0.75);
//             border-radius: 12px;
//             border: 1px solid rgba(255, 255, 255, 0.125);

//             td,
//             th {
//                 min-width: 1em;
//                 border: 1px solid rgba(255, 255, 255, 0.125);
//                 padding: 3px 5px;
//                 vertical-align: top;
//                 box-sizing: border-box;
//                 position: relative;

//                 >* {
//                     margin-bottom: 0;
//                 }
//             }

//             th {
//                 font-weight: bold;
//                 text-align: left;
//                 background-color: #18222c;
//             }

//             .selectedCell:after {
//                 z-index: 2;
//                 position: absolute;
//                 content: "";
//                 left: 0;
//                 right: 0;
//                 top: 0;
//                 bottom: 0;
//                 background: rgba(200, 200, 255, 0.4);
//                 pointer-events: none;
//             }

//             .column-resize-handle {
//                 position: absolute;
//                 right: -2px;
//                 top: 0;
//                 bottom: -2px;
//                 width: 4px;
//                 background-color: #adf;
//                 pointer-events: none;
//             }

//             p {
//                 margin: 0;
//             }
//         }


//         min-height: 100vh !important;
//         // background: #2C2738 !important;
//         //padding-left: 5em;

//         h1 {
//             font-family: "Inter", sans-serif !important;
//             font-style: normal !important;
//             font-weight: 600 !important;
//             font-size: 36px !important;
//             line-height: 22px !important;
//         }

//         h1,
//         h2,
//         h3,
//         h4,
//         h5,
//         h6 {}

//         p {
//             // color: white;

//         }

//         >*+* {
//             margin-top: 0.75em;
//         }


//         ul,
//         ol {
//             padding: 0 2rem;
//         }

//         li {
//             list-style-type: '👉';
//             padding-left: 1em;
//         }

//         pre {
//             color: #FFF;
//             font-family: 'JetBrainsMono', monospace;
//             padding: 0.75rem 1rem;
//             border-radius: 0.5rem;
//             background: black !important;
//             overflow-y: scroll !important;

//             code {
//                 color: inherit;
//                 padding: 0;
//                 background: black !important;
//                 font-size: 0.8rem;
//                 overflow-y: scroll !important;
//             }

//             .hljs-comment,
//             .hljs-quote {}

//             .hljs-variable,
//             .hljs-template-variable,
//             .hljs-attribute,
//             .hljs-tag,
//             .hljs-name,
//             .hljs-regexp,
//             .hljs-link,
//             .hljs-name,
//             .hljs-selector-id,
//             .hljs-selector-class {
//                 color: #F98181;
//             }

//             .hljs-number,
//             .hljs-meta,
//             .hljs-built_in,
//             .hljs-builtin-name,
//             .hljs-literal,
//             .hljs-type,
//             .hljs-params {}

//             .hljs-string,
//             .hljs-symbol,
//             .hljs-bullet {}

//             .hljs-title,
//             .hljs-section {}

//             .hljs-keyword,
//             .hljs-selector-tag {}

//             .hljs-emphasis {}

//             .hljs-strong {
//                 font-weight: 700;
//             }
//         }
//     }
// }

// .overlay-logo {
//     background: rgba(255, 255, 255, 0.9);
//     border-radius: 20px;
//     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
//     padding: 20px;
//     position: relative;
//     z-index: 1;
//     position: fixed;
//     top: 20em;
//     right: 5em;
//     margin: 0 auto;
//     width: 60px;
//     height: 60px;
//     color: white;
//     border: none;
//     border-radius: 30px;
//     font-size: 14px;
//     cursor: pointer;
//     outline: none;
//     backdrop-filter: blur(16px) saturate(180%);
//     -webkit-backdrop-filter: blur(16px) saturate(180%);
//     background-color: rgba(17, 25, 40, 0.75);
// }

// .overlay-logo::before {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     top: -10px;
//     left: -10px;
//     right: -10px;
//     bottom: -10px;
//     // background: linear-gradient(
//     //   to right bottom,
//     //   rgba(255, 255, 255, 0.5),
//     //   rgba(255, 255, 255, 0.1)
//     // );
//     // filter: blur(20px);
// }

// .toggle-button {
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     padding: 1em;
// }

// .toggle-button:hover {
//     padding: 1em;
//     cursor: pointer;
//     opacity: 0.8;
// }

// .ProseMirror .is-empty::before {
//   content: attr(data-placeholder);
//   float: left;
//   color: #aeafaf73;
//   pointer-events: none;
//   height: 0;
// }

// .ProseMirror p.is-editor-empty:first-child::before {
//   content: attr(data-placeholder);
//   float: left;
//   color: #adb5bd;
//   pointer-events: none;
//   height: 0;
// }
</style>
