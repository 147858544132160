<template>
    <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Theme</h1>
    </figure>

    <TabView v-model="activeName" class="demo-tabs px-10" @tab-change="handleTabChange">
        <TabPanel header="Branding">
            <ThemeAssets />
            <h3 class="mt-2">Colors</h3>
            <div style="display: flex;">

            </div>
            <div class="color-container">
                <span class="p-card-subtitle">Primary Color Hex</span>
                <div v-for="(color, index) of colors" :key="index" style="flex-direction: column !important;"
                    class="card flex flex-wrap gap-1 mt-4">
                    <div class="flex items-start">
                        <div class="flex flex-col items-center">
                            <ColorPopup :format="'hex'" title="color" @click="setCurrentColor(index)"
                                @color="setColor" />
                        </div>
                        <div v-for="(colorPalette, paletteIndex) of color.palettes" :key="paletteIndex"
                            class="ml-3 flex flex-col items-center">
                            <Button id="addColorButton" :style="'background: ' + colorPalette" rounded />
                            <span class="mt-2">{{ colorPalette }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="mt-4">Gradient Colors</p>
                <span class="p-card-subtitle">Hex colors for background gradients</span>
                <div style="flex-direction: row !important;" class="card flex flex-wrap gap-1 mt-4">
                    <div style="gap: 1em; width: 100%;" class="flex flex-row items-center"
                        v-for="(color, index) of colors" :key="index">
                        <div style="gap: 1em;" class="flex flex-row items-center">
                            <ColorPopup :format="'hex'" title="dark 1st"
                                @color="setCurrentDarkOneColor" />
                            <ColorPopup :format="'hex'" title="dark 2nd" @click="setCurrentColor(index)"
                                @color="setCurrentDarkTwoColor" />
                            <ColorPopup :format="'hex'" title="light 1st" @click="setCurrentColor(index)"
                                @color="setCurrentLightOneColor" />
                            <ColorPopup :format="'hex'" title="light 2nd" @click="setCurrentColor(index)"
                                @color="setCurrentLightTwoColor" />
                        </div>
                        <div
                            style="flex-grow: 1; width: 40%; height: 100%; display: flex; flex-direction: row; gap: 1em;">
                            <div class="gradient-preview" :style="{
                                background: `radial-gradient(ellipse 100px 100px at 60% 40%,rgba(${colorOne}, 12%) 40%,#0000 90%) no-repeat`
                            }"></div>
                            <div class="gradient-preview" :style="{
                                background: `radial-gradient(ellipse 100px 100px at 60% 40%, rgba(${colorTwo}, 12%) 40%, transparent 90%) no-repeat`
                            }"></div>
                            <div class="gradient-preview" :style="{
                                background: `radial-gradient(ellipse 100px 100px at 60% 40%, rgba(${colorThree}, 12%) 40%, transparent 90%) no-repeat`
                            }"></div>
                            <div class="gradient-preview" :style="{
                                background: `radial-gradient(ellipse 100px 100px at 60% 40%, rgba(${colorFour}, 12%) 40%, transparent 90%) no-repeat`
                            }"></div>

                        </div>

                    </div>

                    <!-- <div class="ml-3 flex flex-col items-center">
                        <Button @click="addColor" id="addColorButton" icon="pi pi-plus" severity="contrast" rounded
                            aria-label="Plus" />
                        <span class="mt-2">Add New</span>
                    </div> -->
                </div>
                <div class="flex flex-col items-start mt-4 mb-10">
                    <Button @click="setColorSchemes" outlined>Generate Palette</Button>
                </div>

            </div>
        </TabPanel>

        <TabPanel header="Navigation Bar">
            <h2>Navigation Items</h2>
            <NavBarItems />
        </TabPanel>
        <TabPanel header="Footer Link Items">
            <h2>Footer Items</h2>
            <FooterItems />
        </TabPanel>
    
    </TabView>

</template>
<script>

import ThemeAssets from "@/components/ThemeAssets.vue";
import NavBarItems from "@/components/ExternalNavBarItems.vue";
import FooterItems from "@/components/FooterItems.vue";
// import ExternalEditor from "@/components/ExternalEditor.vue"
import ColorPopup from "@/components/ColorPopup.vue";
import chroma from "chroma-js"


export default {
    data: () => ({
        activeName: null,
        resetData: null,
        landingPageData: null,
        currentColorIndex: 0,
        colorOne: '255, 0, 0',
        colorTwo: '0, 255, 0',
        colorThree: '0, 0, 255',
        colorFour: '255, 255, 0',
        extraBodyItems: { path: "landing.json" },
        colors: [{ name: "color1", value: null, palettes: [] }]
    }),
    components: {
        NavBarItems,
        FooterItems,
        ColorPopup,
        ThemeAssets
    },
    async mounted() {
        await this.getLandingPage()
        this.setColor(`#6466f1`)
    }, methods: {
        setCurrentColor(index) {
            this.currentColorIndex = index
        },
        setCurrentDarkOneColor(val) {
            console.log("what is the value", val)
            this.colorOne = chroma(val).rgb();
        },
        setCurrentDarkTwoColor(val) {
            console.log("what is the value", val)
            this.colorTwo = chroma(val).rgb();
        },
        setCurrentLightOneColor(val) {
            console.log("what is the value", val)
            this.colorThree = chroma(val).rgb();
        },
        setCurrentLightTwoColor(val) {
            console.log("what is the value", val)
            this.colorFour = chroma(val).rgb();
        },
        async setColor(val) {

            this.colors[this.currentColorIndex].value = `#${val}`
   

            let scale = chroma.scale([chroma(val).brighten(), chroma(val), chroma(val).darken(), chroma(val).darken(2)], chroma.dark).mode('lch').colors(12);
            this.colors[this.currentColorIndex].palettes = scale

        },
        async setColorSchemes() {
            let colorSchemes = ``
            let colorIndex = 0


            for (const color of this.colors) {
                colorIndex += 1
                colorSchemes += `$color-${colorIndex}: ${color.value}; `
                let paletteIndex = 1
                for (const palette of color.palettes) {
                    colorSchemes += `$color-${colorIndex}-${paletteIndex}: ${palette};`
                    paletteIndex += 1
                }
            }

            if(this.colorOne) colorSchemes += `$graident-dark-color-1: ${chroma(this.colorOne).hex()};`
            if(this.colorTwo) colorSchemes += `$graident-dark-color-2: ${chroma(this.colorTwo).hex()};`
            if(this.colorThree) colorSchemes += `$graident-light-color-1: ${chroma(this.colorThree).hex()};`
            if(this.colorFour) colorSchemes += `$graident-light-color-2: ${chroma(this.colorFour).hex()};`

            await this.uploadColorPalette(colorSchemes.trim())
        },
        async uploadColorPalette(colorScheme) {
            const component = this
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var data = {
                    content: colorScheme.replace(/["']/g, "").replace(/"/g, '')
                }

                var raw = JSON.stringify({ ...data, path: "src/css/design-preferences.scss" })

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions)
            } catch (e) {

            }
        },
        addColor() {
            this.colors.push({ name: "color1", value: null, palettes: [] })
        },
        async getLandingPage() {
            const component = this

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_page?path=src/pages/_landing_page.md`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            this.landingPageData = jsonResponse?.docs?.markdown
        }
    }
}
</script>

<style>
#addColorButton {
    height: 4rem !important;
    width: 4rem !important;
}

.p-tabview-panels {
    background-color: transparent !important;
}

.p-tabview-tablist {
    background-color: transparent !important;
}


.p-tabview-panel {
    overflow-y: scroll;
}

.gradient-preview {
    flex-basis: 25%;
}

@media (max-height: 2400px) {

    .p-tabview-panel {
        overflow-y: scroll;
        max-height: 60vh !important;
    }
}
</style>