export function findGithubImagePath(css) {
  // The regex matches URLs in the format you provided, capturing the path portion after 'static/'
  const regex =
    /(https:\/\/raw\.githubusercontent\.com\/[^\/]+\/[^\/]+\/main\/static)(\/img\/[^?]+\.(png|jpg))/g;

  // Replace the matched URL with the new path
  const newCss = css.replace(regex, "$2");

  return newCss;
}

export var handleImageSrc = function () {
  return "hello";
};

export var handlePaste = async function (image, vscodeInstance) {
  const dateForGuid = new Date().toUTCString();
  const guid = btoa(unescape(encodeURIComponent(dateForGuid)));
  const name = `dev-docs-${guid}`;
  await vscodeInstance.vscode.postMessage({
    command: "local_image_save",
    image_file: image,
    name: name,
  });

  let newUrl;
  if (vscodeInstance.external)
    newUrl = replaceImageUrl(
      `/img/dev-docs-assets/${name}.png`,
      vscodeInstance
    );
  else newUrl = replaceImageUrl(`/dev-docs-assets/${name}.png`, vscodeInstance);

  return newUrl;
};

export var replaceImageUrl = function (str, vscodeInstance, options = {}) {
  try {
    
    
    const fullUrls = findImageSrcUrls(str);
    if (fullUrls[0]) {
      const justId = extractId(fullUrls[0]);
      if (vscodeInstance.external)
        return replaceImageUrl(
          `/img/dev-docs-assets/${justId}.png`,
          vscodeInstance
        );
      else
        return replaceImageUrl(
          `/dev-docs-assets/${justId}.png`,
          vscodeInstance
        );
    }
    let regex = /\/dev-docs-assets\/[\S]+\.(?:png|jpe?g|gif|svg|webp)/gi;
    if (vscodeInstance.external)
      regex = /\/img\/dev-docs-assets\/[\S]+\.(?:png|jpe?g|gif|svg|webp)/gi;
    const relativeFolder = vscodeInstance.relativeFolder;
    let newUrl;
    
    
    if (vscodeInstance.external) {
      newUrl = str.replace(
        regex,
        (match) =>
          "https://file.vscode-resource.vscode-cdn.net" +
          relativeFolder +
          "/static" +
          match
      );
    } else {
      newUrl = str.replace(
        regex,
        (match) =>
          "https://file.vscode-resource.vscode-cdn.net" + relativeFolder + match
      );
    }
    
    return newUrl;
  } catch (e) {
    
  }
};

export var findImageSrcUrls = function (str) {
  const regex =
    /https:\/\/file\.vscode-resource\.vscode-cdn\.net\/.*?\/dev-docs-assets\/.*?\.(png|jpg|jpeg|gif|bmp)/gi;
  const matches = str.match(regex);

  if (matches) {
    return matches;
  } else {
    return [];
  }
};

export var isDataURL= function (s) {
    const startsWithRegex = new RegExp('^data:');
    if (startsWithRegex.test(s)) return true
    var regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    return !!s.match(regex);
}

export var isGithubUrl = function (s) {
    const startsWithRegex = new RegExp('^data:');
    if (startsWithRegex.test(s)) return true
    var regex = /(https:\/\/raw\.githubusercontent\.com\/[^\/]+\/[^\/]+\/main\/static)(\/img\/[^?]+\.(png|jpg))/g;
    return !!s.match(regex);
}

export var getOrCreateImage = async function (data) {
    
//   const { str, token, url } = data;
    if(isGithubUrl(data.str) == false && data.str.includes("https")) return {download_url: data.str, src: data.str}
    else if(isDataURL(data.str)) {
        const download_url = await createExternalImage(data)
        const src = findGithubImagePath(download_url).split("?token")[0]
        data["id"] = src
        const fetchedImage = await getExternalImage(data)
        
        return  {download_url: fetchedImage, src}
    } else {
        data["id"] = decodeURIComponent(data.str)
        
        const fetchedImage = await getExternalImage(data)
        
        return  {download_url: fetchedImage, src: extractId(data.str)}
    }
};


export var getExternalImage= async function(data) {
    const { id, token, url } = data;
    
    const valueToCheck = decodeURIComponent(id)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (token) {
      myHeaders.append("Authorization", `Bearer ${token}`)
    }
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    var result = await fetch(`${url}/external_page?path=static/img/dev-docs-assets`, requestOptions)
    const json = await result.json()
    const item = json.docs.find(function (item) { return item.path.includes(valueToCheck) })
    return item.download_url
}

export var createExternalImage = async function(data) {
    const { str, token, url } = data;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const id = generateId()
    var raw = JSON.stringify({
      file: str, id
    });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    var result = await fetch(`${url}/external_images`, requestOptions);
    var json = await result.json()
    return json.content.download_url
}

export var extractId = function (url) {
  const regex = /dev-docs-assets\/(dev-docs-[^\.]+)/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  }

  return null;
};

export var generateId = function() {
    const dateForGuid = new Date().toUTCString();
    const guid = btoa(unescape(encodeURIComponent(dateForGuid)));
    const name = `dev-docs-assets/dev-docs-${guid}`;
    return name
}

export var saveImageToCloud = async function (
  dataUrl,
  imageUrl,
  token,
  baseUrl
) {
  var myHeaders = new Headers();
  
  
  myHeaders.append("Content-Type", "application/json");
  try {
    myHeaders.append("Content-Type", "application/json");
    const name = extractId(imageUrl);
    var request_body = { file: dataUrl, name: name };
    var raw = JSON.stringify(request_body);
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    var url = baseUrl;
    var saveResponse = await fetch(`${url}/images`, requestOptions);
    var jsonResponse = await saveResponse.json();
  } catch (e) {
    
  }
};

export const syncImages = async function (content, token, baseUrl) {
  if (!token || !baseUrl) return;

  var imageUrls = findImageSrcUrls(content);
  for (const imageUrl of imageUrls) {
    
    const dataUrl = await getDataUrlFromVscodeImage(imageUrl);
    
    await saveImageToCloud(dataUrl, imageUrl, token, baseUrl);
  }
};

async function convertImageToDataURL(imageUrl) {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = function () {
      resolve(reader.result);
    };

    reader.onerror = function () {
      reject(new Error("Failed to convert image to data URL"));
    };

    reader.readAsDataURL(blob);
  });
}

export const getDataUrlFromVscodeImage = async (vscodeImageUrl) => {
  const dataUrl = await convertImageToDataURL(vscodeImageUrl);
  return dataUrl;
};

const getExtension = (filename) => {
  const ext = filename.split(".").pop().toLowerCase();
  return ext === "jpg" ? "jpeg" : ext;
};
