<template>
    <div style="max-height: 100vh" class="flex-shrink-0 w-full max-w-sm">
        <figure class="px-10 pt-5">
            <img src="https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/new%20dev-docs%20logo%20white%20text%20(4).png?v=1668386911915"
                alt="Shoes" class="rounded-xl" />
        </figure>
        <div class="card-body">
            <div class="form-control mt-6">
                <button style="color: white !important" class="github-button" @click="loginInWithGithub()">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" fill="#1E1E1E" />
                        <g id="Dev-docs sign up-active-on hover">
                            <rect width="1440" height="814" transform="translate(-148 -372)" fill="#121220" />
                            <g id="Frame 5184">
                                <g id="SSO Button/active">
                                    <rect x="-19.75" y="-14.75" width="449.5" height="59.5" rx="3.75" fill="#736AFD"
                                        fill-opacity="0.1" />
                                    <rect x="-19.75" y="-14.75" width="449.5" height="59.5" rx="3.75" stroke="#736AFD"
                                        stroke-width="0.5" />
                                    <g id="Logo">
                                        <path id="Vector"
                                            d="M15.0001 0C6.71682 0 0 6.71564 0 15.0001C0 21.6276 4.29797 27.2502 10.258 29.2337C11.0076 29.3725 11.2829 28.9083 11.2829 28.5121C11.2829 28.1544 11.2689 26.9727 11.2625 25.7193C7.0894 26.6267 6.20884 23.9495 6.20884 23.9495C5.52651 22.2157 4.54337 21.7548 4.54337 21.7548C3.18248 20.8237 4.64595 20.8428 4.64595 20.8428C6.15225 20.9487 6.9454 22.3886 6.9454 22.3886C8.28324 24.6818 10.4545 24.0188 11.3104 23.6356C11.445 22.666 11.8338 22.0044 12.2627 21.6298C8.9311 21.2504 5.42863 19.9642 5.42863 14.2167C5.42863 12.5791 6.01461 11.2409 6.97422 10.1905C6.81847 9.81259 6.30507 8.287 7.11951 6.22084C7.11951 6.22084 8.37912 5.81768 11.2456 7.75843C12.442 7.42597 13.7253 7.25939 15.0001 7.25374C16.2748 7.25939 17.559 7.42597 18.7578 7.75843C21.6209 5.81768 22.8787 6.22084 22.8787 6.22084C23.6952 8.287 23.1815 9.81259 23.0258 10.1905C23.9875 11.2409 24.5695 12.579 24.5695 14.2167C24.5695 19.9779 21.0604 21.2465 17.7203 21.6178C18.2583 22.0833 18.7377 22.9962 18.7377 24.3956C18.7377 26.4026 18.7203 28.018 18.7203 28.5121C18.7203 28.9112 18.9903 29.379 19.7507 29.2317C25.7074 27.246 30 21.6254 30 15.0001C30 6.71564 23.2841 0 15.0001 0Z"
                                            fill="white" />
                                        <path id="Vector_2"
                                            d="M6.58388 25.0413C6.54527 25.1285 6.40768 25.1547 6.28263 25.0949C6.1551 25.0375 6.0834 24.9184 6.12476 24.8307C6.16268 24.7408 6.30027 24.7157 6.42753 24.7761C6.55534 24.8334 6.62813 24.9536 6.58388 25.0413ZM7.4486 25.8128C7.36478 25.8905 7.20085 25.8544 7.08959 25.7316C6.9746 25.609 6.95309 25.4452 7.03816 25.3662C7.12461 25.2886 7.28357 25.3249 7.39883 25.4476C7.51382 25.5715 7.53615 25.7343 7.44847 25.8129M8.04186 26.7999C7.93405 26.8748 7.75785 26.8046 7.64907 26.6483C7.54139 26.4921 7.54139 26.3046 7.65141 26.2294C7.76061 26.1543 7.93405 26.2219 8.04435 26.377C8.15189 26.5359 8.15189 26.7234 8.04173 26.8001M9.04502 27.9433C8.94864 28.0495 8.74349 28.0211 8.59321 27.876C8.43962 27.7343 8.39674 27.5331 8.49339 27.4268C8.59087 27.3204 8.79726 27.3503 8.94864 27.4941C9.10127 27.6356 9.14787 27.8383 9.04515 27.9433M10.3416 28.3294C10.2992 28.467 10.1015 28.5295 9.90244 28.4711C9.70363 28.4108 9.57348 28.2495 9.6136 28.1104C9.65496 27.9719 9.85349 27.9066 10.0541 27.9692C10.2526 28.0292 10.3831 28.1893 10.3417 28.3294M11.8173 28.493C11.8223 28.638 11.6534 28.7583 11.4444 28.7609C11.2341 28.7654 11.0641 28.6481 11.0619 28.5056C11.0619 28.3591 11.227 28.24 11.4371 28.2366C11.6461 28.2324 11.8173 28.3489 11.8173 28.493ZM13.2669 28.4374C13.292 28.5789 13.1467 28.7242 12.9392 28.7628C12.7351 28.8 12.5463 28.7128 12.5202 28.5726C12.4948 28.4275 12.6429 28.2823 12.8465 28.2447C13.0545 28.2086 13.2404 28.2936 13.2669 28.4374Z"
                                            fill="white" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                    Login with Github
                </button>
            </div>
            <div class="form-control mt-6">
                <el-tooltip class="box-item" effect="dark" content="Coming soon!" placement="bottom-start">
                    <button style="color: white !important" class="github-button" @click="loginInWithGithub()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path
                                d="M1.73747 11.5431L0.056062 17.151C-0.0962233 17.6602 0.0682496 18.2223 0.47032 18.5397L14.9999 30.0001L10.3496 20.0868L1.73747 11.5431Z"
                                fill="#FFA100" />
                            <path
                                d="M28.2625 11.543L29.9439 17.1508C30.0962 17.6601 29.9317 18.2221 29.5296 18.5396L15 29.9999L20.1101 18.2424L28.2625 11.543Z"
                                fill="#FFA100" />
                            <path
                                d="M14.9998 29.9997L14.9998 18.64L9.47426 11.5427L5.60883 9.45508L1.7373 11.5427L14.9998 29.9997Z"
                                fill="#FF6D18" />
                            <path
                                d="M15 29.9997L15.0001 17.9529L20.5256 11.5427L24.7072 9.45508L28.2625 11.5427L15 29.9997Z"
                                fill="#FF6D18" />
                            <path d="M14.9999 30L20.5254 11.543H9.47437L14.9999 30Z" fill="#FC3819" />
                            <path
                                d="M1.73755 11.5431H9.4806L6.1482 0.426563C5.97763 -0.142188 5.23437 -0.142188 5.05771 0.426563L1.73755 11.5431Z"
                                fill="#FC3819" />
                            <path
                                d="M28.2626 11.5431H20.5195L23.8458 0.426563C24.0164 -0.142188 24.7597 -0.142188 24.9363 0.426563L28.2626 11.5431Z"
                                fill="#FC3819" />
                        </svg>

                        Login with Gitlab
                    </button>
                </el-tooltip>
            </div>
            <div class="form-control">
                <h4>by signing in you agree to the policies below</h4>
                <label>
                    <a href="https://app.termly.io/document/terms-of-use-for-saas/2089f33f-b962-4e79-b2c2-5d2832abb223"
                        class="label-text-alt link link-hover">Terms of Service</a>
                </label>
                <label>
                    <a href="https://app.termly.io/document/privacy-policy/f93b0777-1325-40b0-b83a-e8f1fc82c82c"
                        class="label-text-alt link link-hover">Privacy Policy</a>
                </label>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    methods: {
        async loginInWithGithub() {
            this.$authInstance.setAuth()
        }
    }
}
</script>

<style>
.login-card {
    background: #0E1117 !important;
    border: 1px solid #2F3643;
    border-radius: 5px;
}

html {
    overflow: hidden;
}

.github-button {
    border-radius: 4px;
    border: 0.5px solid #736AFD;
    background: rgba(115, 106, 253, 0.10);
    display: flex;
    width: 450px;
    height: 60px;
    padding: 15px 230px 15.741px 20px;
    align-items: center;
    gap: 15px;
}

.background-button-github {
    border-radius: 4px;
    border: 0.5px solid #736AFD;
    background: rgba(115, 106, 253, 0.10);
}

.background-button-gitlab {
    background: linear-gradient(180deg, #A17EFE 0%, #C63CD7 100%);
    border-radius: 5px;
}
</style>