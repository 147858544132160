import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

import Component from "./GradientText.vue";

export default Node.create({
  name: "gradientTextItem",

  group: "block",

  content: "block+",

  draggable: true,

  addStorage() {
    return {
      disableDrag: false,
    };
  },
  addOptions() {
    return {
      HTMLAttributes: {
        class: null,
        style: null,
      },
      validate: undefined,
    };
  },
  parseHTML() {
    return [
      {
        tag: "gradient-text",
      },
    ];
  },
  addAttributes() {
    return {
      type: {
        default: "info",
      },
      style: {
        default: this.options.HTMLAttributes.style || `margin: 0;
        font-size: 160px;
        font-weight: 800;
        color: #fff;
        letter-spacing: -0.03em;`,
      },
      words: {
        default: "these are words"
      },
      title: {
        default: "More Info"
      },
      class: {
        default: this.options.HTMLAttributes.class || "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["gradient-text", HTMLAttributes, 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },
});
