<template>
    <el-dialog v-model="dialogFormVisible" closed="closeDialog" title="Choose a Template Integration">
        <el-select v-model="value" class="m-2" placeholder="Select" size="large">
            <el-option v-for="item in templateOptions" :key="item" :label="item" :value="item" />
        </el-select>
        <el-button @click="insertTemplate()">Submit</el-button>
    </el-dialog>
</template>
  
<script>

import blockTemplates from '@/blockTemplates/blockTemplates'


export default {
    props: {
        show: Boolean
    },
    data: () => ({
        dialogFormVisible: false, templateOptions: ["tweet", "grid", "tweet grid", "social header"], value: ''
    }),
    unmounted() {
        this.$emit("close")
    },
    methods: {
        closeDialog() {
            this.$emit("close")
            return
        },
        async insertTemplate() {
            if(this.value == '') return this.closeDialog()
            if(this.value == 'grid') this.$emit("insert", blockTemplates.titleGrid)
            if(this.value == 'tweet') this.$emit("insert", blockTemplates.tweet)
            if(this.value == 'tweet grid') this.$emit("insert", blockTemplates.tweetGrid)
            if(this.value == 'social header') this.$emit("insert", blockTemplates.socialHeader)
        },
        async saveContent() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var url = await this.$authInstance.getBaseUrl()

            } catch (e) {
                
            }
        }
    },
    async mounted() {
        
        this.dialogFormVisible = this.show
    }
}

</script>
