<template>
    <NodeViewWrapper>
        <h1 :style="node?.attrs?.style">
            <div v-for="(word, index) in words" :key="index" class="heading-line">
                <span class="white-text">{{ word }} &nbsp</span>
                <span class="heading-line-gradient">{{ word }} &nbsp</span>
            </div>
        </h1>
        <NodeViewContent />
    </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'

export default {
    components: {
        NodeViewWrapper, NodeViewContent
    },
    props: nodeViewProps,
    data: () => ({
        words: ["hey"],
    }),
    mounted() {
        this.words = this.node?.attrs?.words.split(" ")
        this.$nextTick(function () {
            let words = document.getElementsByClassName('heading-line');
            const gradients = [
                'linear-gradient(to left, #ed8936, #ed64a6)',
                'linear-gradient(to left, #38b2ac, #0bc5ea)',
                'linear-gradient(to left, #ecc94b, #48bb78)',
                'linear-gradient(to left, #38b2ac, #0bc5ea)',
                'linear-gradient(to left, #ecc94b, #48bb78)'
            ];
            for (let i = 0; i < words.length; i++) {
                let gradient = gradients[i % gradients.length]; // Cycle through gradients
                let word = words[i];
                let gradientElement = word.querySelector('.heading-line-gradient');
                let regularWord = word.querySelector('.white-text');
                //gradientElement.style.backgroundImage = gradient;

                let keyframes = [
                    { opacity: 1, backgroundImage: gradient, easing: "ease-in"},
                    { opacity: 0, backgroundImage: "transparent", easing: "ease-in" },
                    { opacity: 0, backgroundImage: "transparent", easing: "ease-out"},
                    { opacity: 0, backgroundImage: "transparent", easing: "ease-out" }
                ];

                let numberOfDelay = i + 1
                let timingOptions = {
                    duration: 2000 * words.length,
                    iterations: Infinity,
                    delay: numberOfDelay * 2000
                }

                gradientElement.animate(keyframes, timingOptions);
            }
        })
    }
}
</script>

<style>
.heading-line {
    line-height: 1;
    display: inline-block;
    padding-bottom: 1em;
    position: relative;
    text-align: center;
}

.white-text {
    line-height: 1;
    display: block;
    position: absolute;
    color: #fff;
    pointer-events: none;
    width: 100%;
}

.heading-line-gradient {
    -webkit-text-fill-color: transparent;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
</style>
