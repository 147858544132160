<template>
    <el-dialog v-model="dialogFormVisible" closed="closeDialog" title="Upload Your Open API Yaml or Json Files">
        <el-button v-if="!addFile" @click="addFile = true" type="primary">Add new File</el-button>
        <el-upload v-model:file-list="fileList" :disabled="newFileName?.length <= 1" :name="newFileName" :headers="{ 'Authorization': 'Bearer ' + token }"
            :on-success="uploadSuccess" class="upload-demo" v-if="apiUrl" :action="apiUrl" :on-preview="handlePreview"
            :on-remove="handleRemove">
            <el-input v-if="addFile" minlength="1" :show-word-limit="true" v-model="newFileName"
                placeholder="new file name" clearable><template #append>
                    <el-button v-if="addFile" type="primary">Click to upload</el-button>
                </template></el-input>
            <template #tip>
                <div class="el-upload__tip">
                    Your current Open API Files.
                </div>
            </template>
        </el-upload>
    </el-dialog>
</template>
  
<script>
import { lowlight } from 'lowlight'
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'


export default {
    props: {
        show: Boolean
    },
    components: {
        EditorContent,
        FloatingMenu,
    },
    data: () => ({
        dialogFormVisible: false,
        editor: null,
        apiUrl: null,
        addFile: false,
        token: '',
        newFileName: "",
        fileList: []
    }),
    unmounted() {
        
        this.$emit("closeapi")
    },
    methods: {
        closeDialog() {
            this.$emit("closeapi")
        },
        async uploadSuccess(response, uploadFile, uploadFiles) {
            
            
            var extension;
            if (uploadFiles[uploadFiles.length - 1].name.includes(".yaml")) extension = ".yaml"
            else if (uploadFiles[uploadFiles.length - 1].name.includes(".json")) extension = ".json"
            uploadFiles[uploadFiles.length - 1].name = `${this.newFileName}${extension}`
            this.fileList = uploadFiles

            var apiConfig = {}

            for(const file of uploadFiles) {
                var fileExtension;
                if(file.name.includes(".yaml")) fileExtension = ".yaml"
                else if(file.name.includes(".json")) fileExtension = ".json"
                var nameWithoutExt = file.name.split(fileExtension)[0]
                apiConfig[nameWithoutExt] = {specPath: `examples/${nameWithoutExt}${fileExtension}`, outputDir: `docs/api/${nameWithoutExt}`}
            }
            await this.saveConfig(apiConfig)
        },
        async getAPIConfigs() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var token = await this.$authInstance.getToken()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/openapi`, requestOptions)
                var jsonResponse = await saveResponseTwo.json()
                return jsonResponse
            } catch (e) {
                
            }

        },
        async saveConfig(apiConfig) {
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify(apiConfig);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/openapi_config`, requestOptions)
            } catch (e) {
                
            }
        },
        async saveContent() {
            
            var data = this.editor.getJSON()
            
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, title: this.title, tags: this.tags || [], user: org, section: this.section }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_styling`, requestOptions)
            } catch (e) {
                
            }
        }
    },
    async mounted() {
        this.dialogFormVisible = this.show
        var baseUrl = await this.$authInstance.getBaseUrl()
        this.token =  await this.$authInstance.getToken()
        this.apiUrl = `${baseUrl}/openapi`
        var configsObject = await this.getAPIConfigs()
        
        this.fileList = configsObject.configs
    }
}

</script>
